import request from "../index";
const inviteapi = {
    // 校验邀请码
    verifyCode: (code) =>
        request({
            url: `/dash/invitationcode/judge/${code}`,
            method: "get",
        }),
    // 判断当前登陆人有没有在这个域底下
    memExists: (data: {
        domainID: string;
        organizationPath: string;
        admin: String;
    }) =>
        request({
            url: `/domain/judge/domain/userexist`,
            method: "post",
            data: data,
        }),
    // 加入组织
    joinOrg: (param: {
        userid: string;
        domainUuid: string;
        organizePath: string;
        admin: boolean;
        phoneNumber: string;
        personUUid: string;
        name: string;
    }) =>
        request({
            url: `dash/domain/join/bycode`,
            method: "post",
            data: param,
        }),
    // 获取域下所有组织 或者 对应organizePath组织及其子组织列表
    getOwnOrgList: (param: { organizePath: string }, domainid, personid) =>
        request({
            url: `/domain/organize/list`,
            method: "post",
            data: param,
            headers: {
                "Grpc-Metadata-GspDomainID": domainid,
                "Grpc-Metadata-GspPersonID": personid,
            },
        }),
};
export default inviteapi;
