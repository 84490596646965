import './Register.scss'
import React, { useEffect, useState } from 'react';
import withRouter from '../../hoc/withRouter'
import { Button, Checkbox, Form, Input, Space, Typography } from 'antd';
import loginApi from '../../request/api/loginapi'
import { Utils } from '../../utils';
import { Statistic } from 'antd';
import FooterBox from '../../commons/FooterBox';
import SCSvg from '../../assets/svg/servicecool.svg';
import LoginLogoSvg from '../../assets/svg/loginlogo.svg';
const { Countdown } = Statistic;
function Register({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    // 展示提示信息
    const { showMessage, setStore } = Utils();
    // 表单全部数据结构
    let [registerData, setRegData] = useState({});
    const [checkLogin, setCheckLogin] = useState(false); //检查账户是否已绑定
    const [formInfo, setFormInfo] = useState({
        regName: {
            label: '用户名（选填）',
            required: false,
            type: 'text',
            placeholder: '请填写用户名',
            extra: "以英文字母开头，英文字母和数字组合，最少 4 个字符",
            ruleMsg: '用户名不符合要求',
            callBack: () => ({
                validator(_, val) {
                    const reg = /^[a-zA-Z][a-zA-Z0-9]{3,}$/g;
                    if (!val || reg?.test(val)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('用户名不符合要求'));
                },
            }),
        },
        displayName: {
            label: '昵称（选填）',
            required: false,
            type: 'text',
            placeholder: '请输入昵称',
            callBack: () => ({
                validator() {
                    return Promise.resolve();
                },
            }),
        },
        mobile: {
            label: '手机号码',
            required: true,
            type: 'text',
            placeholder: '请输入手机号码',
            show: false,
            rule: 'mobile',
            controlInfo: '',
            extra: "",
            pattern: new RegExp(/^1\d{10}$/, "g"),
            ruleMsg: '请输入正确的手机号码格式',
            callBack: () => ({
                validator() {
                    return Promise.resolve();
                },
            }),
        },
        captcha: {
            label: '验证码',
            required: true,
            type: 'text',
            placeholder: '请输入验证码',
            show: false,
            rule: '',
            controlInfo: '',
            // extra: "验证码十分钟内有效",
            pattern: new RegExp(/^\d{6}$/, "g"),
            ruleMsg: '验证码错误',
            callBack: () => ({
                validator() {
                    return Promise.resolve();
                },
            }),
        },
        regPwd: {
            label: '密码',
            required: true,
            type: 'password',
            placeholder: '请输入密码',
            pattern: new RegExp(/((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])|(?=.*[0-9])((?=.*[a-z])|(?=.*[A-Z]))(?=.*[!@#$%^&*()_+=\-~[\]{};':"\\|,./<>?`])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=\-~[\]{};':"\\|,./<>?`]))^[0-9A-Za-z!@#$%^&*()_+=\-~[\]{};':"\\|,./<>?`]{8,}$/, "g"),
            ruleMsg: '密码不符合要求',
            extra: '至少 8 位，至少包含数字, 大写字母, 小写字母, 特殊字符中的 3 项',
            callBack: () => ({
                validator() {
                    return Promise.resolve();
                },
            }),
            dependencies: ['regPwd']
        },
        protocol: {
            label: '我已阅读并同意',
            required: true,
            type: 'checked',
            placeholder: '',
            link: ['服务协议', '隐私政策'],
            callBack: () => ({
                validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('请勾选并已阅读协议！')),
            }),
        },
        confirmPwd: {
            label: '确认密码',
            required: true,
            type: 'password',
            placeholder: '请再次输入密码',
            callBack: ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('regPwd') == value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('密码不匹配'));
                },
            }),
            dependencies: ['regPwd']
        },
        nextStep: {
            label: '下一步',
            type: 'primary',
            loading: false,
            className: 'next-step width100',
            disabled: true,
        },
        lastStep: {
            label: '上一步',
            type: 'primary',
            className: 'default-btn',
            loading: false,
        },
        confirm: {
            label: '提交',
            type: 'primary',
            loading: false,
            className: 'default-btn',
            disabled: true,
        },
    });
    // 显示计时器
    const [showResend, setShowResend] = useState(true);
    const [time, setTime] = useState(Date.now() + 1000 * 60);
    // 注册表单的变量
    const [register, setRegister] = useState<any[]>(['mobile', 'captcha', 'protocol', 'operate']);
    const [registerBtnList, setRegBtnList] = useState<any[]>(['nextStep']);
    const [registerForm] = Form.useForm();
    const regValues = Form.useWatch([], registerForm);
    const [bindAcc, setBindAcc] = useState('');
    const [errcode, setErrCode] = useState('');
    // 然后将钩子函数放到第二个位置**************************************************************
    // 监听表单字段有效时取消按钮的禁用效果
    useEffect(() => {
        if (checkLogin && regValues?.mobile && bindAcc) {
            setFromInfoVal('mobile', { pattern: new RegExp(`(?!^${bindAcc}$)^1[0-9]{10}$`, "g"), ruleMsg: '账户已绑定' });
            setBindAcc('');
            setCheckLogin(false);
        } else if (regValues?.captcha && errcode) {
            setFromInfoVal('captcha', { pattern: new RegExp(`(?!^${errcode}$)^\d{6}$`, "g") });
            setErrCode('');
        } else {
            setFromInfoVal('mobile', { pattern: new RegExp(/^1\d{10}$/, "g"), ruleMsg: '请输入正确的手机号码格式' });
            setFromInfoVal('captcha', { pattern: new RegExp(/^\d{6}$/, "g") });
        }
        const obj = { ...(regValues || {}) };
        delete obj?.displayName;
        delete obj?.regName;
        const list = Object.values(obj);
        const arr = list.filter(item => {
            if (item) {
                return true;
            }
        })
        if (arr.length && arr.length == list.length) {
            registerForm.validateFields().then(
                () => {
                    if (regValues?.mobile) {
                        setFromInfoVal('nextStep', { disabled: false });
                    } else {
                        setFromInfoVal('confirm', { disabled: false });
                    }
                },
                () => {
                    if (regValues?.mobile) {
                        setFromInfoVal('nextStep', { disabled: true });
                    } else {
                        setFromInfoVal('confirm', { disabled: true });
                    }
                },
            ).catch(() => { });
        } else {
            if (regValues?.mobile) {
                setFromInfoVal('nextStep', { disabled: true });
            }
            else {
                setFromInfoVal('confirm', { disabled: true });
            }
        }
    }, [regValues])
    // 然后将处理函数放到第三个位置**************************************************************
    // 修改formInfo中某个按钮的某个属性值
    const setFromInfoVal = (filed, data) => {
        let obj = { ...formInfo };
        obj[filed] = {
            ...obj[filed],
            ...data
        }
        setFormInfo(obj);
    }
    // 创建表单模板
    const getFormItem = (list, btnList, type?) => {
        const formList = list.map((key) => {
            if (!['checked']?.includes(formInfo[key]?.type) && key != 'operate') {
                return (<Form.Item
                    key={key}
                    label={formInfo[key]?.label}
                >
                    <Space.Compact block>
                        <Form.Item
                            className='sub-form-item'
                            name={key}
                            rules={[{ required: formInfo[key]?.required, message: '值不能为空！', whitespace: true }, { pattern: formInfo[key]?.pattern, message: formInfo[key]?.ruleMsg }, formInfo[key]?.callBack]} dependencies={formInfo[key]?.dependencies}
                            extra={formInfo[key]?.extra}
                        >
                            {
                                ['regPwd', 'confirmPwd']?.includes(key) ? <Input.Password prefix={formInfo[key]?.prefix} type={formInfo[key]?.type} placeholder={formInfo[key]?.placeholder} autoComplete="off" /> :
                                    <Input prefix={formInfo[key]?.prefix} type={formInfo[key]?.type} placeholder={formInfo[key]?.placeholder} autoComplete={type == 'register' ? "off" : "auto"} />
                            }
                        </Form.Item>
                    </Space.Compact>
                    {
                        key == 'mobile' ? <div className='send-code'>
                            {showResend ? <span className='pointer' onClick={() => { sendCode(type) }}>获取验证码</span> : <Countdown value={time} onFinish={() => { setShowResend(true) }} format={"s"} suffix={'s后重新获取'} />}
                        </div> : null
                    }
                </Form.Item >)
            } else if (formInfo[key]?.type == 'checked') {
                return (<Form.Item key={key}>
                    <Form.Item name={key} valuePropName={formInfo[key]?.type} noStyle rules={[
                        formInfo[key]?.callBack
                    ]}>
                        <Checkbox>{formInfo[key]?.label}</Checkbox>
                    </Form.Item>
                    {formInfo[key]?.link.map((link, index) => {
                        const str = index > 0 ? '和' : '';
                        return <div className='inline-block font-12' key={index}>
                            {str}
                            <a onClick={(event) => { operate(event, key, link) }}>
                                {link}
                            </a>
                        </div>
                    })
                    }
                </Form.Item>)
            } else if (key === 'operate') {
                return <Form.Item key={key}>
                    {
                        btnList.map(btn => {
                            return <Button key={btn} type={formInfo[btn]?.type} htmlType={formInfo[btn]?.htmlType} className={formInfo[btn]?.className} onClick={(event) => operate(event, btn)} loading={formInfo[btn]?.loading} disabled={formInfo[btn]?.disabled}>
                                {formInfo[btn]?.label}
                            </Button>
                        })
                    }
                </Form.Item>
            }
        });
        return formList;
    }
    // 发送验证码调用
    const sendCode = (type) => {
        if (type == 'register') {
            registerForm.validateFields(['mobile']).then(res => {
                if (showResend) {
                    setTime(Date.now() + 1000 * 60);
                    loginApi.reqCode(res.mobile).then(res => {
                        showMessage("success", "发送成功请注意查收");
                    }).catch(err => {
                        if (err?.code == 3) {
                            showMessage('error', '验证码发送请求过多，请 1 分钟后重试。');
                        }
                    })
                    setShowResend(false);
                } else {
                    showMessage('error', "请一分钟后再次发送！");
                }
            }).catch(() => { })
        } else {
            setShowResend(false);
        }
    }
    // 点击注册功能按钮进行的操作
    const operate = (e, btn, text?) => {
        e?.stopPropagation();
        const val = registerForm.getFieldsValue() || {};
        switch (btn) {
            case 'nextStep':
                setRegData(Object.assign({}, registerData, { mobile: val.mobile, code: val.captcha }));
                // 这边需要发一个登录请求判断当前手机号有没有被绑定
                loginApi.checkBind({ mobile: val['mobile'], code: val['captcha'] }).then(res => {
                    const data = res || {};
                    if (data['error'] == "无效的验证码" && !data['msg']) {
                        registerForm.setFieldValue('captcha', null);
                        registerForm.setFieldValue('captcha', val['captcha']);
                        setErrCode(val['captcha']);
                    } else if (!data['error'] && data['msg'] == "mobile already used") {
                        registerForm.setFieldValue('mobile', null);
                        registerForm.setFieldValue('mobile', val['mobile']);
                        setCheckLogin(true);
                        setBindAcc(val.mobile);
                    } else if (!data['error'] && data['msg'] == "mobile available") {
                        setRegister(['regName', 'displayName', 'regPwd', 'confirmPwd', 'operate']);
                        setRegBtnList(['lastStep', 'confirm']);
                    }
                }).catch(err => {
                })
                break;
            case 'lastStep':
                setRegister(['mobile', 'captcha', 'protocol', 'operate']);
                setRegBtnList(['nextStep']);
                setRegData(Object.assign({}, registerData, { name: val.regName, pwd: val.regPwd, displayName: val.displayName || val.regName }));
                break;
            case 'protocol':
                const url = text == '服务协议' ? '/f/website/userfile' : '/f/website/privacy';
                const win = window.open(url, '_blank');
                if (win) {
                    win.onload = function () {
                        win.document.title = text;
                    }
                }
                break;

            case 'confirm':
                const param = {
                    name: registerData['name'] || val?.regName || `u${new Date().getTime()}`,
                    displayName: registerData['displayName'] || val?.displayName,
                    pwd: registerData['pwd'] || val?.regPwd,
                    mobile: registerData['mobile'],
                    code: registerData['code']
                };
                loginApi.signup(param).then(res => {
                    showMessage('success', '注册成功前往登录！');
                    setTimeout(() => {
                        router.navigate('/login');
                    }, 500);
                }).catch(err => {
                    if (err?.code == 8) {
                        showMessage('error', '用户名已被使用');
                    }
                })
                break;
            default:
                break;
        }
    }
    return (
        <div className='register flex-space-between' data-component="register">
            <div className='shrink-0 width-50 register-bg'>
                <img src={LoginLogoSvg} alt="" className='margin-T30 margin-L30' height={24} width={150} />
            </div>
            <div className='flex-column flex-1'>
                <div className='parcel align-self-center margin-auto login-main'>
                    <div className="flex-center" >
                        <div className='shrink-0 align-self-center bold font-33 margin-R10'>欢迎使用</div>
                        <img src={SCSvg} alt='' width={178} height={24} onClick={() => router.navigate('/home')} className='align-self-center' />
                    </div>
                    <div className='login-register'>
                        <div className='register-form-box'>
                            <div className='register-form-con'>
                                <Form
                                    name="normal_register"
                                    className="register-form"
                                    initialValues={{}}
                                    form={registerForm}
                                    layout="vertical"
                                >
                                    {getFormItem(register, registerBtnList, 'register')}
                                </Form>
                            </div>
                        </div>
                        <div className='prompt-action' onClick={() => { router.navigate('/login') }}><span>已有帐户？</span><Button type="link">返回登录</Button></div>
                    </div>

                </div>
                <FooterBox />
            </div>
        </div>
    )
}
export default withRouter(Register);