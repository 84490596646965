import './AccountSetting.scss';
import withRouter from '../../hoc/withRouter';
import { Spin, Tabs, Modal, Form, Input, Space, Button } from 'antd';
import { useEffect, useState } from 'react';
import { Utils } from '../../utils';
import { useImmer } from 'use-immer';
import usersetupapi from '../../request/api/usersetupapi';
function AccountSetting({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { showMessage, setStore } = Utils();
    const [tabVal, setTabVal] = useState('basicInfo');
    const tabList = [{
        key: 'basicInfo',
        label: '基本信息'
    }];
    const [spinning, setSpinning] = useState(false);
    const fieldList = ['mobile', 'nickName', 'userName', 'userEmail'];
    const [field, setField] = useImmer({
        mobile: {
            key: "mobile",
            label: "绑定手机",
            value: "",
            count: true,
        },
        nickName: {
            key: "nickName",
            label: "昵称",
            value: "",
            placeholder: "昵称",
            extra: '最长 10 个字符',
            rules: [
                {
                    message: "该项不能为空",
                    whitespace: true
                },
                () => ({
                    validator(_, value) {
                        if (value && value?.length <= 10) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('昵称不符合要求'));
                    },
                })
            ],
            count: true,
            maxlength: 10,
        },
        userName: {
            key: "userName",
            label: "用户名",
            value: "",
            placeholder: "用户名",
            extra: "以英文字母开头，英文字母和数字组合，最少 4 个字符",
            rules: [
                {
                    required: true,
                    whitespace: true,
                    message: "该项不能为空",
                },
                {
                    pattern: /^[a-zA-Z][a-zA-Z0-9]{3,}$/g,
                    message: "用户名不符合要求",
                },
            ],
            count: false,
        },
        userEmail: {
            key: "userEmail",
            label: "邮箱地址",
            value: "",
            placeholder: "邮箱地址",
            rules: [
                {
                    required: true,
                    whitespace: true,
                    message: "该项不能为空",
                },
            ],
            count: false,
        },
    })
    // 修改基本信息模态框数据
    const [modalTitle, setModalTitle] = useState("修改昵称");
    const [open, setOpen] = useState(false);
    const [userSetUpForm] = Form.useForm();
    const [curField, setCurField]: any = useState({});
    const [subLoading, setSubLoading] = useState(false);
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        getUserInfo();
    }, [])
    // 然后将处理函数放到第三个位置**************************************************************
    const operate = (type, data?) => {
        switch (type) {
            case "submit":
                if (curField?.key == 'nickName') {
                    subNickName(data);
                } else if (curField?.key == 'userName') {
                    subName(data);
                } else if (curField?.key == 'userEmail') {
                    subEmail(data);
                }
                break;
            case "reviseField":
                {
                    if (data?.key == 'nickName') {
                        setModalTitle('修改昵称');
                    } else if (data?.key == 'userName') {
                        setModalTitle('修改用户名');
                    } else if (data?.key == 'userEmail') {
                        setModalTitle('修改邮箱地址');
                    }
                    setCurField(data);
                    if (data?.key != 'userEmail') {
                        userSetUpForm.setFieldValue(data?.key, data?.value);
                    }
                    setOpen(true);
                }
                break;
            case 'selectTab':
                setTabVal(data);
                break;
            case "closeModal":
                userSetUpForm?.resetFields();
                setOpen(false);
                break;
            default:
                break;
        }
    }
    // 获取用户信息
    const getUserInfo = () => {
        setSpinning(true);
        usersetupapi.getUserInfo().then((res: any) => {
            setSpinning(false);
            const data = res?.user || {};
            setField(draft => {
                for (const key of ['mobile', 'nickName', 'userName', 'userEmail']) {
                    draft[key].value = key == 'mobile' ? data?.mobileNumber || '' : key == 'nickName' ? data?.displayName || '' : key == 'userName' ? data?.name || '' : key == 'userEmail' ? data?.email || '' : '';
                }
            })
        }).catch(err => {
            setSpinning(false);
        })
    }
    // 修改昵称
    const subNickName = (form) => {
        setSubLoading(true);
        usersetupapi.subNickName({ displayName: form?.nickName || '' }).then((res: any) => {
            setSubLoading(false);
            const data = res?.user || {};
            setField(draft => {
                for (const key of ['mobile', 'nickName', 'userName', 'userEmail']) {
                    draft[key].value = key == 'mobile' ? data?.mobileNumber || '' : key == 'nickName' ? data?.displayName || '' : key == 'userName' ? data?.name || '' : key == 'userEmail' ? data?.email || '' : '';
                }
            })
            operate('closeModal');
        }).catch(err => {
            setSubLoading(false);
        })
    }
    // 修改用户名
    const subName = (form) => {
        setSubLoading(true);
        usersetupapi.subName({ name: form?.userName || '' }).then((res: any) => {
            setSubLoading(false);
            const data = res?.user || {};
            setField(draft => {
                for (const key of ['mobile', 'nickName', 'userName', 'userEmail']) {
                    draft[key].value = key == 'mobile' ? data?.mobileNumber || '' : key == 'nickName' ? data?.displayName || '' : key == 'userName' ? data?.name || '' : key == 'userEmail' ? data?.email || '' : '';
                }
            });
            operate('closeModal');
        }).catch(err => {
            setSubLoading(false);
            if (err == "name is already in use") {
                showMessage("error", "用户名已被使用");
            }
        })
    }
    // 修改邮箱地址
    const subEmail = (form) => {
        setSubLoading(true);
        usersetupapi.subEmail({ email: form?.userEmail || '' }).then((res: any) => {
            setSubLoading(false);
            const data = res?.user || {};
            setField(draft => {
                for (const key of ['mobile', 'nickName', 'userName', 'userEmail']) {
                    draft[key].value = key == 'mobile' ? data?.mobileNumber || '' : key == 'nickName' ? data?.displayName || '' : key == 'userName' ? data?.name || '' : key == 'userEmail' ? data?.email || '' : '';
                }
            });
            operate('closeModal');
        }).catch(err => {
            setSubLoading(false);
            if (err == "invalid email") {
                showMessage("error", "邮箱地址不合法");
            }
        })
    }
    return (
        <div className='account-settting scrollbar width-90 height100 margin-auto bg-white radius-20 padding-20' data-component='account-settting'>
            <Spin tip="加载中..." spinning={spinning}>
                <div className='bold font-20'>账户设置</div>
                <Tabs defaultActiveKey="domain" items={tabList} onChange={(e) => { operate('selectTab', e) }} />
                <div>
                    {(fieldList || [])?.map(key => {
                        return <div className='margin-B16' key={key}>
                            <span className='label'>{field[key]?.label + '：'}</span>
                            <span className='value'>{field[key]?.value}</span>
                            {
                                key != 'mobile' ? <span className='revise-btn pointer' onClick={() => operate('reviseField', field[key])}>修改</span> : ''
                            }
                        </div>
                    })}
                </div>
            </Spin>
            {/* 修改基本信息模态框 */}
            <Modal
                title={modalTitle}
                open={open}
                maskClosable={false}
                centered={true}
                wrapClassName="account-setting-modal"
                footer={null}
                onCancel={() => {
                    operate("closeModal");
                }}
            >
                <Form
                    onFinish={(e) => {
                        operate("submit", e);
                    }}
                    form={userSetUpForm}
                    labelCol={{ span: 6 }}
                >
                    <Form.Item
                        label={curField?.label}
                        name={curField?.key}
                        rules={curField?.rules}
                        extra={curField?.extra}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={subLoading}>
                                提交
                            </Button>
                            <Button onClick={() => operate("closeModal")}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default withRouter(AccountSetting);