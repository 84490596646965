import request from "../index";
import cookie from "react-cookies";

const homeauthapi = {
    // 创建管理域接口，暂时只有域名称和授权码
    // 授权码测试使用：01H27MTD7JYNW5JJ5PP7GY304S
    createDomain: (params: {
        userID: string;
        metadata: { name: string };
        authCode: string;
    }) =>
        request({
            url: `/dash/domain`,
            method: "post",
            data: params,
        }),
    // 判断当前用户是否是管理员
    judgmentUser: () =>
        request({
            url: `/domain/checkadmin`,
            method: "get",
        }),
};
export default homeauthapi;
