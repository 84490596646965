import axios from "axios";
import { getPublicData } from "../utils/savedata";
// 创建axios实例
const instance = axios.create({
    // 基本请求路径的抽取
    baseURL: window.location.origin,
    // 这个时间是每次请求的过期时间，请求超过十分钟会取消
    timeout: 600000,
});
// 请求拦截器
instance.interceptors.request.use(async (config) => {
    const data = await getPublicData();
    config.headers
        .set("X-Gsp-Auth", localStorage.getItem("token"))
        .set("Grpc-Metadata-GspUID", data["handleApp"]?.uid);

    if (
        config?.url?.includes("msgbox/") ||
        [
            "/domain/judge/domain/userexist",
            "/domain/checkadmin",
        ]?.includes(config?.url || "")
    ) {
        config.headers
            .set(
                "Grpc-Metadata-GspDomainID",
                data["handleApp"]?.currentDomainInfo?.id
            )
            .set(
                "Grpc-Metadata-GspPersonID",
                data["handleApp"]?.currentDomainInfo?.personUUID
            );
    }
    return config;
});

// 响应拦截器
instance.interceptors.response.use(
    (res) => {
        return res.data;
    },
    (err) => {
        if (
            err?.response?.status == 403 &&
            err?.response?.data?.auth == "token invalid"
        ) {
            localStorage.removeItem("currentDomain");
            localStorage.removeItem("token");
            localStorage.removeItem("wsCurOrg");
            localStorage.removeItem("spCurOrg");
            localStorage.removeItem("lastupdatetime");
            localStorage.removeItem("UserAcceptPush");
            localStorage.removeItem("ConnectionTab");
            // token过期的时候去登录界面
            sessionStorage.setItem("sessionStatus", "lapse");
            localStorage.setItem("redirect_url", window.location.href);
            window.open(window.location.origin + "/f/website/login", "_self");
            return;
        } else if (
            err?.request?.responseURL?.includes("/auth/sigin") &&
            err?.response?.status == 401
        ) {
            // 等保登录错误处理
            return Promise.reject(err?.response?.data);
        } else if (
            err?.request?.responseURL?.includes("/auth/send/sms/code?mobile") &&
            err?.response?.status == 500
        ) {
            return Promise.reject(err?.response?.data);
        } else if (err?.response?.data?.code) {
            return Promise.reject(err?.response?.data);
        }
        return Promise.reject(
            err?.response?.data?.error ||
                err?.response?.data?.err ||
                err?.response?.data?.message ||
                err?.response?.data?.auth ||
                err?.response?.data?.exist ||
                err?.response?.data?.msg ||
                "请求错误"
        );
    }
);

export default instance;
