import withRouter from '../../hoc/withRouter';
import './AppCard.scss'
import { ArrowRightOutlined } from '@ant-design/icons';
function AppCard({ app }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    // 然后将钩子函数放到第二个位置**************************************************************
    // 然后将处理函数放到第三个位置**************************************************************
    return (
        <div className='app-card pointer radius-10 margin-B12' data-component="app-card">
            <div className='app-content flex-column radius-10 relative app-hover-style' >
                <div className='top flex-1 word-break-all'>
                    {
                        app?.img ? <img src={app?.img} alt="" /> : null
                    }
                </div>
                <div className='bottom font-14 bold shrink-0 flex-space-between'>
                    <div className='label'>{app?.label}</div>
                    <ArrowRightOutlined />
                </div>
            </div>

        </div>
    )
}
export default withRouter(AppCard);