import './Login.scss'
import React, { useCallback, useEffect, useState } from 'react';
import withRouter from '../../hoc/withRouter'
import { LockOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input, Modal, Space, Tabs, TabsProps } from 'antd';
import loginApi from '../../request/api/loginapi'
import { Utils } from '../../utils';
import { Statistic } from 'antd';
import ResetPwd from '../../commons/ResetPwd/ResetPwd';
import FooterBox from '../../commons/FooterBox';
import LoginLogoSvg from '../../assets/svg/loginlogo.svg';
import SCSvg from '../../assets/svg/servicecool.svg';

const { Countdown } = Statistic;
function Login({ router }) {
  // 将所有需要的变量都放到最顶上********************************************************************
  // 展示提示信息
  const { showMessage, setStore } = Utils();
  const [sessionStatus, setSessionStatus] = useState(sessionStorage.getItem("sessionStatus"));
  // 表单全部数据结构
  const [formInfo, setFormInfo] = useState({
    name: {
      label: '用户名/手机号码',
      required: true,
      type: 'text',
      placeholder: '请输入用户名/手机号码',
      callBack: () => ({
        validator() {
          return Promise.resolve();
        },
      }),
    },
    mobile: {
      label: '手机号码',
      required: true,
      type: 'text',
      placeholder: '请输入手机号码',
      show: false,
      rule: 'mobile',
      controlInfo: '',
      extra: "",
      pattern: new RegExp(/^1\d{10}$/, "g"),
      ruleMsg: '请输入正确的手机号码格式',
      callBack: () => ({
        validator() {
          return Promise.resolve();
        },
      }),
    },
    captcha: {
      label: '验证码',
      required: true,
      type: 'text',
      placeholder: '请输入验证码',
      show: false,
      rule: '',
      controlInfo: '',
      // extra: "验证码十分钟内有效",
      pattern: new RegExp(/^\d{6}$/, "g"),
      ruleMsg: '验证码错误',
      callBack: () => ({
        validator() {
          return Promise.resolve();
        },
      }),
    },
    pwd: {
      label: '密码',
      required: true,
      type: 'password',
      placeholder: '请输入密码',
      callBack: () => ({
        validator() {
          return Promise.resolve();
        },
      }),
    },
    protocol: {
      label: '我已阅读并同意',
      required: false,
      type: 'checked',
      placeholder: '',
      link: ['服务协议', '隐私政策'],
      prefix: <LockOutlined className="site-form-item-icon" />,
    },
    loginBtn: {
      label: '登录',
      type: 'primary',
      loading: false,
      htmlType: 'submit',
      disabled: true
    },
    captchaLoginBtn: {
      label: '登录',
      type: 'primary',
      loading: false,
      htmlType: 'submit',
      disabled: true
    }
  });
  // 登录表单的所有变量及方法
  const [accLoginForm] = Form.useForm();
  const [mobLoginForm] = Form.useForm();
  // useWatch用于直接获取 form 中字段对应的值。通过该 Hooks 可以与诸如 useSWR 进行联动从而降低维护成本
  const accValues = Form.useWatch([], accLoginForm);
  const mobValues = Form.useWatch([], mobLoginForm);
  // 显示计时器
  const [showResend, setShowResend] = useState(true);
  const [time, setTime] = useState(Date.now() + 1000 * 60);
  const [currentTab, setCurrentTab] = useState('acc')
  // 重置密码
  const [resetPwd, setResetPwd] = useState(false);
  // 模态框状态
  const [promptOpen, setPromptOpen] = useState(false);
  // 当前操作类型
  const [curOperate, setCurOperate]: any = useState({});
  const [modalShow, setModalShow] = useState(false);
  // 然后将钩子函数放到第二个位置**************************************************************
  useEffect(() => {
    if (sessionStatus == 'lapse') {
      let timeout = setTimeout(() => {
        sessionStorage.removeItem("sessionStatus");
        setSessionStatus('');
        clearTimeout(timeout);
      }, 3000);
    }
  }, [])
  useEffect(() => {
    // 添加键盘事件监听器
    document.addEventListener('keyup', goToLogin);
    // 清理函数，在组件卸载或更新时移除事件监听器
    return () => {
      document.removeEventListener('keyup', goToLogin);
    }
  }, [promptOpen, modalShow])
  // 监听账户密码登录表单字段有效时取消按钮的禁用效果
  useEffect(() => {
    // 在这里不需要判断这个参数
    delete accValues?.protocol;
    const list = Object.values(accValues || {});
    const arr = list.filter(item => {
      if (item) {
        return true;
      }
    })
    if (arr.length && arr.length == list.length) {
      accLoginForm.validateFields().then(
        () => {
          setFromInfoVal('loginBtn', { disabled: false });
        },
        () => {
          setFromInfoVal('loginBtn', { disabled: true });
        },
      ).catch(() => { });
    } else {
      setFromInfoVal('loginBtn', { disabled: true });
    }
  }, [accValues])
  // 监听手机号验证码登录表单字段有效时取消按钮的禁用效果
  useEffect(() => {
    // 在这里不需要判断这个参数
    delete mobValues?.protocol;
    const list = Object.values(mobValues || {});
    const arr = list.filter(item => {
      if (item) {
        return true;
      }
    })
    if (arr.length && arr.length == list.length) {
      mobLoginForm.validateFields().then(
        () => {
          setFromInfoVal('captchaLoginBtn', { disabled: false });
        },
        () => {
          setFromInfoVal('captchaLoginBtn', { disabled: true });
        },
      ).catch(() => { });
    } else {
      setFromInfoVal('captchaLoginBtn', { disabled: true });
    }
  }, [mobValues])
  // 点击回车绑定同意并继续按钮
  const goToLogin = useCallback((e) => {
    if (promptOpen && modalShow) {
      setModalShow(false)
    }
    if (e?.key == 'Enter' && promptOpen && !modalShow) {
      operate(e, 'onOk')
    } else {
      return;
    }
  }, [promptOpen, modalShow]);
  // 然后将处理函数放到第三个位置**************************************************************
  // 修改formInfo中某个按钮的某个属性值
  const setFromInfoVal = (filed, data) => {
    let obj = { ...formInfo };
    obj[filed] = {
      ...obj[filed],
      ...data
    }
    setFormInfo(obj);
  }
  // 创建表单模板
  const getFormItem = (list, btnList, type?) => {
    const formList = list.map((key) => {
      if (!['checked']?.includes(formInfo[key]?.type) && key != 'operate') {
        return (<Form.Item
          key={key}
          label={formInfo[key]?.label}
        >
          <Space.Compact block>
            <Form.Item
              className='sub-form-item'
              name={key}
              rules={[{ required: formInfo[key]?.required, message: '值不能为空！', whitespace: true }, { pattern: formInfo[key]?.pattern, message: formInfo[key]?.ruleMsg }, formInfo[key]?.callBack]} dependencies={formInfo[key]?.dependencies}
              extra={formInfo[key]?.extra}
            >
              <Input prefix={formInfo[key]?.prefix} type={formInfo[key]?.type} placeholder={formInfo[key]?.placeholder} autoComplete={type == 'register' ? "off" : "auto"} />
            </Form.Item>
            {
              key == 'mobile' ? <div className='send-code'>
                {showResend ? <span className='pointer' onClick={() => { sendCode(type) }}>获取验证码</span> : <Countdown value={time} onFinish={() => { setShowResend(true) }} format={"s"} suffix={'s后重新获取'} />}
              </div> : null
            }
          </Space.Compact>

        </Form.Item >)
      } else if (formInfo[key]?.type == 'checked') {
        return (<Form.Item key={key}>
          <Form.Item name={key} valuePropName={formInfo[key]?.type} noStyle rules={[
            formInfo[key]?.callBack
          ]}>
            <Checkbox>{formInfo[key]?.label}</Checkbox>
          </Form.Item>
          {formInfo[key]?.link.map((link, index) => {
            const str = index > 0 ? '和' : '';
            return <div className='inline-block font-12' key={index}>
              {str}
              <a onClick={(event) => { operate(event, key, link) }}>
                {link}
              </a>
            </div>
          })
          }
          <span className='reset-pwd' onClick={() => setResetPwd(true)}>重置密码</span>
        </Form.Item>)
      } else if (key === 'operate') {
        return <Form.Item key={key}>
          {
            btnList.map(btn => {
              return <Button key={btn} type={formInfo[btn]?.type} htmlType={formInfo[btn]?.htmlType} className={formInfo[btn]?.className + ' width100'} loading={formInfo[btn]?.loading} disabled={formInfo[btn]?.disabled}>
                {formInfo[btn]?.label}
              </Button>
            })
          }
        </Form.Item>
      }
    });
    return formList;
  }
  // tabs设置及方法调用
  const tabs: TabsProps['items'] = [
    {
      key: 'acc',
      label: '账户登录',
      children: (<Form
        name="normal_login"
        className="login-form"
        initialValues={{}}
        onFinish={(e) => clickLogin(e, 'acc')}
        form={accLoginForm}
        layout="vertical"
      >
        {
          getFormItem(['name', 'pwd', 'protocol', 'operate'], ['loginBtn'], 'login')
        }
      </Form>),
    },
    {
      key: 'mob',
      label: '手机验证码登录',
      children: (<Form
        name="normal_login"
        className="login-form"
        initialValues={{}}
        onFinish={(e) => clickLogin(e, 'mob')}
        form={mobLoginForm}
        layout="vertical"
      >
        {
          getFormItem(['mobile', 'captcha', 'protocol', 'operate'], ['captchaLoginBtn'], 'login')
        }
      </Form>),
    }
  ];
  // 点击按钮进行的操作
  const operate = (e, btn, text?) => {
    e?.stopPropagation();
    switch (btn) {
      case 'protocol':
        const url = text == '服务协议' ? '/f/website/userfile' : '/f/website/privacy';
        const win = window.open(url, '_blank');
        if (win) {
          win.onload = function () {
            win.document.title = text;
          }
        }
        break;
      case 'onOk':
        setPromptOpen(false);
        submitData(curOperate?.values, curOperate?.type);
        break;
      default:
        break;
    }
  }
  // 切换tab
  const onChange = (key: string) => {
    setCurrentTab(key);
  };
  // 发送验证码调用
  const sendCode = (type) => {
    if (type == 'login') {
      mobLoginForm.validateFields(['mobile']).then(res => {
        if (showResend) {
          setTime(Date.now() + 1000 * 60);
          loginApi.reqCode(res.mobile).then(res => {
            showMessage("success", "发送成功请注意查收");
          }).catch(err => {
            if (err?.code == 3) {
              showMessage('error', '验证码发送请求过多，请 1 分钟后重试。');
            }
          })
          setShowResend(false);
        } else {
          showMessage('error', "请一分钟后再次发送！");
        }
      }).catch(() => { })
    } else {
      setShowResend(false);
    }
  }
  // 点击登录按钮
  const clickLogin = (values: any, type) => {
    setCurOperate({ values: values, type: type });
    if (values?.protocol) {
      submitData(values, type);
    } else {
      setPromptOpen(true);
      setModalShow(true);
    }
  }
  // 登录操作
  const submitData = (values: any, type) => {
    let param;
    if (type == 'acc') {
      setFromInfoVal('loginBtn', { loading: true });
      if (/^1\d{10}$/.test(values?.name)) {
        param = { mobile: values.name, pwd: values.pwd };
      } else {
        param = { name: values.name, pwd: values.pwd };
      }
    } else if (type = "mob") {
      setFromInfoVal('captchaLoginBtn', { loading: true });
      param = { mobile: values.mobile, code: values.captcha };
    }
    loginApi.signin(param).then(res => {
      setFromInfoVal('loginBtn', { loading: false });
      setFromInfoVal('captchaLoginBtn', { loading: false });
      if (res) {
        ['name', 'uid', 'displayName']?.forEach(key => {
          setStore(key, res[key]);
        });
        localStorage.setItem('token', res['token']);
        router.navigate('/home/auth');
      }
    }).catch(err => {
      if ([1, 5]?.includes(err?.code)) {
        showMessage('error', '用户名或密码错误');
      } else if (err?.code == 2) {
        showMessage('error', '用户名或密码错误超过 5 次，账户被锁定，请 5 分钟后重试。');
      } else if (err?.code == 3) {
        showMessage('error', '验证码发送请求过多，请 1 分钟后重试。');
      } else if (err?.code == 4) {
        showMessage('error', '密码已使用超过 90 天，请重置密码');
        setResetPwd(true);
      } else if (err?.code == 6) {
        showMessage('error', '验证码无效');
      } else if (err?.code == 7) {
        showMessage('error', '手机号无效');
      }
      setFromInfoVal('loginBtn', { loading: false });
      setFromInfoVal('captchaLoginBtn', { loading: false });
    });
  };
  return (<div className='login flex-space-between' data-component="login">
    <div className='shrink-0 width-50 login-bg'>
      <img src={LoginLogoSvg} alt="" className='margin-T30 margin-L30' height={24} width={150} />
    </div>
    <div className='flex-column flex-1'>
      {
        resetPwd ? <ResetPwd showLogin={(e) => { setResetPwd(e); accLoginForm.resetFields(); mobLoginForm.resetFields() }} /> :
          <div className='flex align-items-center margin-auto login-main'>
            <div className='parcel width100'>
              <div className="flex-center" >
                <div className='shrink-0 align-self-center bold font-33 margin-R10'>欢迎使用</div>
                <img src={SCSvg} alt='' width={178} height={24} onClick={() => router.navigate('/home')} className='align-self-center' />
              </div>
              <div className='login-register'>
                {sessionStatus == 'lapse' ? <Alert
                  message="会话失效请重新登录"
                  type="error"
                  closable
                /> : null}
                <div className='login-form-box'>
                  <Tabs activeKey={currentTab} items={tabs} onChange={onChange} tabBarGutter={16} indicator={{ size: 20, align: 'center' }} />
                </div>
                <div className='prompt-action'><Button type="link" onClick={() => { router.navigate('/register') }}>立即注册</Button></div>
              </div>
            </div>
          </div>
      }
      <FooterBox />
    </div>

    <Modal title={'提示'} centered={true} open={promptOpen} footer={() => {
      return <Space>
        <Button type='default' onClick={() => setPromptOpen(false)}>返回</Button>
        <Button type='primary' onClick={(e) => { operate(e, 'onOk') }}>同意并继续</Button>
      </Space>
    }} onCancel={() => setPromptOpen(false)} style={{ textAlign: 'center' }}>
      <div className='inline-block font-12'>
        登录前请先阅读并同意
        <a className='margin-L8' onClick={(event) => { operate(event, 'protocol', '服务协议') }}>
          服务协议
        </a>
        <a className='margin-L8' onClick={(event) => { operate(event, 'protocol', '隐私政策') }}>
          隐私政策
        </a>
      </div>
    </Modal>
  </div>
  )
}
export default withRouter(Login);

