import "./HomeAuth.scss";
import withRouter from "../../hoc/withRouter";
import { Button, Modal, Input, Form, Spin, Empty } from "antd";
import { useEffect, useState } from "react";
import homeauthapi from '../../request/api/homeauthapi';
import { Utils } from "../../utils";
import officialwebapi from "../../request/api/officialwebapi";
import AuthPanel from "../AuthPanel/AuthPanel";
import EmptySvg from '../../assets/svg/empty.svg';
import moment from "moment";
function HomeAuth({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { showMessage, setStore, storeData } = Utils();
    const token = localStorage.getItem("token");
    const inviteCode = sessionStorage.getItem('inviteCode');
    // 创建管理域数据
    const [spinning, setSpinning] = useState(false);
    const [domainStatus, setDomainStatus] = useState(false);
    const [domainLoading, setDomainLoading] = useState(false);
    const [domainForm] = Form.useForm();
    const [domainList, setDomainList] = useState<any[]>([]);
    const [domainListCopy, setDomainListCopy] = useState<any[]>([]);
    const [firstInit, setfirstInit] = useState(false);
    // 要在useEffect中存
    const [bindDomain, setBindDomain] = useState<any>({});
    // 然后将钩子函数放到第二个位置**************************************************************
    // 请求管理域列表
    useEffect(() => {
        setfirstInit(true);
        if (token && !inviteCode) {
            getJoinOrgList()
        }
    }, []);
    useEffect(() => {
        if (inviteCode && firstInit) {
            router.navigate("/invite");
        }
    }, [inviteCode, firstInit]);
    // 然后将处理函数放到第三个位置**************************************************************
    // 获取管理域列表
    const getJoinOrgList = () => {
        setSpinning(true);
        officialwebapi.getJoinOrgList().then((res) => {
            setSpinning(false);
            if (Array.isArray(res) && res?.length > 0) {
                let exitObj = {};
                // 新的处理方式，为什么不用JSON.stringify(item) == JSON.stringify(storeData?.currentDomainInfo)判断两个对象的值是否相等呢，是因为后端返回的对象类型字段值的顺序有可能不一样。就导致同样的数据，会被替换掉
                for (const item of res || []) {
                    if (item?.id == storeData?.currentDomainInfo?.id && item?.personUUID == storeData?.currentDomainInfo?.personUUID) {
                        exitObj = item;
                        break;
                    }
                }
                if (!storeData?.domainId || !Object.keys(exitObj)?.length) {
                    setBindDomain(res[0]);
                } else {
                    setBindDomain(exitObj);
                }
                setDomainListCopy(res);
                setDomainList(res);
            } else {
                setStore("currentDomainInfo", {});
                setBindDomain({});
                localStorage.setItem("currentDomain", JSON.stringify({}));
                setDomainList([]);
                setDomainListCopy([]);
            }
        }).catch(err => {
            setSpinning(false);
            showMessage('error', err);
        })
    }

    const domainSubmit = (value) => {
        setDomainLoading(true);
        const param = { authCode: value?.authCode, metadata: { name: value?.name }, userID: storeData?.uid }
        homeauthapi.createDomain(param).then((res) => {
            setDomainLoading(false);
            showMessage('success', "企业（机构）创建成功！");
            setDomainStatus(false);
            getJoinOrgList();
        }).catch((err) => {
            setDomainLoading(false);
            showMessage('error', err);
        })
    };
    const operate = (type, data?) => {
        switch (type) {
            case "createDomain":
                domainForm.resetFields();
                setDomainStatus(true);
                break;
            case "selectApp":
                {
                    // 加入的域列表大于0，或者当前域的domainid存在，并且在当前域中是启用状态并且还是这个域的管理员或者超级管理员才能进入后台管理，否则进入无权限界面
                    if ((domainList?.length || storeData?.domainId) && storeData?.currentDomainInfo?.valid) {
                        if (data?.key == 'domain') {
                            homeauthapi?.judgmentUser().then(res => {
                                const result = res?.toString() || 'false';
                                // 返回结果是true的话代表当前登录人是当前企业的管理员，否则没有权限进入后台管理界面
                                if (result == 'true') {
                                    window.open(data.url, '_blank');
                                } else {
                                    router.navigate(`/error?message=您没有权限进入当前界面`);
                                }
                            }).catch(err => {
                                showMessage('error', err);
                            });
                        } else {
                            window.open(data.url, '_blank');
                        }
                    } else {
                        showMessage("warning", "请先加入企业（机构）或者创建企业（机构）！");
                    }
                }
                break;
            default:
                break;
        }
    }
    return (
        <div className="home-auth" data-component="home-auth">
            <Spin tip="加载中..." spinning={spinning}>
                <div className="content-box">
                    <div className="radius-10 bg-white padding-20">
                        <div className="banner-style">
                            <img className="width100" src={require('../../assets/images/banner.png')} alt="banner" />
                        </div>
                        <AuthPanel title="全部企业" cardData={domainList} type="domain" bindDomain={bindDomain} change={e => {
                            operate(e?.type)
                        }} />
                        <Modal
                            open={domainStatus}
                            title="创建管理域"
                            footer={null}
                            onCancel={() => setDomainStatus(false)}
                            className="home-auth-modal"
                            maskClosable={false}
                        >
                            <Form
                                name="domainForm"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{}}
                                form={domainForm}
                                onFinish={domainSubmit}
                            >
                                <Form.Item
                                    label="授权码"
                                    name="authCode"
                                    rules={[{ required: true, message: '值不能为空', whitespace: true }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="企业（机构）名称"
                                    name="name"
                                    rules={[{ required: true, message: '值不能为空' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" loading={domainLoading} htmlType="submit">
                                        创建
                                    </Button>
                                    <Button onClick={() => { setDomainStatus(false); domainForm.resetFields() }} className="margin-L16">
                                        取消
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                    <div className="margin-T10 radius-10 bg-white padding-20">
                        {
                            domainListCopy?.length ?
                                storeData?.currentDomainInfo?.authCodeStatus?.notExpired ?
                                    !storeData?.currentDomainInfo?.valid ? <span className="prompt-info">您的账户待激活，请联系企业管理员。</span> : storeData?.currentDomainInfo?.apps?.length ?
                                        <AuthPanel title="应用列表" cardData={storeData?.currentDomainInfo?.apps} type="app" change={e => {
                                            operate(e?.type, e?.data)
                                        }} /> : <Empty image={EmptySvg} description="暂无应用" /> : <Empty image={EmptySvg} description={`授权码（${storeData?.currentDomainInfo?.authCodeStatus?.code || ''}）已过期（${moment(storeData?.currentDomainInfo?.authCodeStatus?.expiredTime || '')?.format('YYYY-MM-DD HH:mm:ss')}），请重新激活。`} /> :
                                <span className="prompt-info">您的账户待激活，请联系企业管理员。</span>
                        }
                    </div>
                </div>
            </Spin>

        </div>
    );
}

export default withRouter(HomeAuth);
