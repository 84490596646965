
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setPublicData } from "./savedata";

export function Utils() {
    // 创建记忆化的选择器
    const rememberState = createSelector(
        state => state,
        (state) => {
            // 这里是选择器的逻辑
            setPublicData(state);
            // 返回一个新的引用（例如对象或数组）
            return {
                name: state?.handleApp?.name,
                currentDomainInfo: state?.handleApp?.currentDomainInfo,
                domainId: state?.handleApp?.currentDomainInfo?.id,
                uid: state?.handleApp?.uid,
                orgList: state?.handleApp?.orgList,
                homeAuthCut: state?.handleApp?.homeAuthCut,
                displayName: state?.handleApp?.displayName
            }
        }
    );
    // 获取store数据
    const storeData = useSelector(rememberState);
    const dispatch = useDispatch();
    const showMessage = (type, str = '') => {
        message[type](str);
    };
    const setStore = (type, val) => {
        dispatch({ type: type, val: val });
    }
    return { showMessage, setStore, storeData };
}