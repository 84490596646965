import withRouter from '../../hoc/withRouter';
import './file.scss'
function PrivacyFile(props) {
    return (
        <div className='privacy-file'><section className="user-service-agreement">
            <h4>用户隐私协议</h4>
            <h4>前言</h4>
            <main>
                <p>
                    感谢您使用<span className="emphasis"
                    >综合服务管理平台</span
                    >。为了切实保护用户隐私权，我们制定了本《用户隐私协议》，阐述了本平台收集、管理和保护用户个人信息的政策和措施。本协议适用于您通过计算机设备、移动端或其他设备使用的所有服务。
                </p>
                <p>
                    西安点通软件信息技术有限公司（下文简称“点通软件”、“我们”和“我们的”）深知隐私对您的重要性，并会尊重您的隐私。请在向点通软件提交个人数据前，阅读、了解本《隐私协议》，（下文简称“本协议”）。一旦您使用或确认接受本协议，即表示您已充分阅读、理解并同意接受本协议的约束。
                </p>
                <p>
                    本协议阐述了点通如何处理您的个人数据，我们制定本协议的目的是在于帮助您了解以下内容：
                </p>
                <p>(1)&nbsp; 我们如何收集和使用您的个人数据；</p>
                <p>(2)&nbsp; 我们如何对您个人信息管理；</p>
                <p>(3)&nbsp; 我们如何对个人信息保护；</p>
                <p>(4)&nbsp; 您如何行使您的个人信息权力；</p>
                <p>(5)&nbsp; 本协议如何更新；</p>
                <p>(6)&nbsp; 未成年人的隐私；</p>
                <p>(7)&nbsp; 联系点通软件方式；</p>
                <article>
                    <br />
                    <h4>一、签约主体以及协议范围</h4>
                    <p>
                        本《用户隐私协议》是您与西安点通软件信息技术有限公司（以下简称“点通”或者“我们”）就使用<span
                            className="emphasis"
                        >综合服务管理平台</span
                        >申请注册用户，就用户相关信息的使用和保护而订立的有效合约。
                    </p>
                    <br />
                    <h4>二、个人信息收集</h4>
                    <p>
                        您同意，在注册本平台账户时，我们将记录您提供的相关个人信息，如：姓名、科室、手机号码等。这些信息是为您提供服务的基础。
                    </p>
                    <br />
                    <h4>三、个人信息管理</h4>
                    <p>
                        为了向您提供更优质的服务，我们会在以下情况中使用您的个人信息：
                    </p>
                    <p>(1)&nbsp; 符合相关法律法规的要求；</p>
                    <p>(2)&nbsp; 根据您的授权；</p>
                    <p>(3)&nbsp; 根据本平台服务协议的约定。</p>
                    <p>
                        未经您本人允许，我们不会向任何第三方披露您的个人信息，除以下情形外：
                    </p>
                    <div>
                        <p>(1)&nbsp; 符合司法机关或行政机关依法定程序要求；</p>
                        <p>
                            (2)&nbsp;
                            维护本平台合法权益，向用户提起诉讼或仲裁时；
                        </p>
                        <p>(3)&nbsp; 法律法规规定的其他情形。</p>
                        <p>我们收集的信息将仅用于以下用途：</p>
                        <p>(1)&nbsp; 提供和改进我们的服务；</p>
                        <p>(2)&nbsp; 向您发送重要通知和更新信息；</p>
                        <p>(3)&nbsp; 为您提供客户支持和技术支持。</p>
                        <br />
                    </div>
                    <h4>四、个人信息保护</h4>
                    <p>
                        点通依照《中国人民共和国个人信息保护法》、《中国人民共和国网络安全法》等相关法律法规要求，采取一切合理措施保护用户个人信息的安全。
                    </p>
                    <p>
                        我们深知您的个人信息对您的隐私和安全非常重要，因此我们将尽一切方法保护您的个人信息，以确保您使用我们的服务时的安全和便利。我们将采取安全措施，如加密、防火墙等，以保护您的个人信息不受未经授权的访问、使用或泄露。我们还将定期对我们的安全措施进行评估和更新，以确保我们的保护措施一直处于最优状态。点通软件承诺不会出售或泄露您的个人数据。
                    </p>
                    <br />
                    <h4>五、行使个人信息权利</h4>
                    <p>
                        您有权查询、更改、补充您的信息，可以通过本平台自助修改，也可以联系我们的工作人员修改相关账号信息。
                    </p>
                    <p>
                        您有权删除您的个人信息。在以下情况中，您可以向我们提出删除个人信息的请求：
                    </p>
                    <p>(1) &nbsp; 如果我们处理个人信息行为违反法律法规；</p>
                    <p>(2) &nbsp; 如果我们未经您同意收集、使用您的个人信息；</p>
                    <p>
                        (3) &nbsp; 如果我们处理个人信息的行为违反了与您的约定；
                    </p>
                    <p>(4) &nbsp; 如果您不再使用本平台，或您注销了账号；</p>
                    <p>(5) &nbsp; 如果我们不再为您提供服务。</p>
                    <p>
                        收到您的删除请求后，我们将按照要求删除您的个人信息，并在3个工作日内反馈结果。
                    </p>
                    <br />
                    <h4>六、本协议更新</h4>
                    <p>
                        我们保留修改本隐私协议的权利。我们会在本页面上发布更新后的隐私协议。您可以定期查看本页面，以便了解我们如何使用您的个人信息。
                    </p>
                    <p>
                        (1) &nbsp;
                        如果我们对本隐私协议进行重大更改，我们将通过本小程序上发布通知或通过电子邮件发送通知，以便您及时获得相关信息。
                    </p>
                    <p>
                        (2) &nbsp;
                        如果您继续使用我们的服务，则表示您同意更新后的隐私协议。
                    </p>
                    <h4>七、未成年人的隐私</h4>
                    <p>
                        我们的服务不适用于未成年人（18岁以下）。我们不会故意收集未成年人的个人信息。如果您是未成年人，请在您的监护人的同意下使用本小程序。
                    </p>
                    <h4>八、联系我们</h4>
                    <p>
                        如您对本协议有任何疑问，请通过以下方式联系我们，我们收到反馈后将在3个工作日内给予回复：<br />电话：
                        029-89584658<br />电子邮件： info@dian-tong.com<br />地址：西安市高新区唐兴路6号唐兴数码大厦328室
                    </p>
                    <p>
                        西安点通软件信息技术有限公司<br />版本:
                        v1.0.0，自2023年03月28日起生效。
                    </p>
                </article>
            </main>
        </section></div>
    )
}

{/* export default PrivacyFile; */ }
export default withRouter(PrivacyFile);
