import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

export default function withRouter(WrapperedCpn) {
    return function (props) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        const [searchParams] = useSearchParams();
        const query = Object.fromEntries(searchParams);
        return <WrapperedCpn {...props} router={{ navigate, location, params, query }} />
    }
}
