import request from "../index";
const loginApi = {
    // 注册接口
    signup: (params: {
        name: string;
        pwd: string;
        mobile: string;
        code: string;
    }) =>
        request({
            url: "/auth/sigup",
            method: "post",
            data: params,
        }),
    // 登录接口：name和mobie二者都可以，前端通过判断用户输入是否是mobile格式来确定给后端传name还是mobile参数
    signin: (params: { name?: string; mobile?: string; pwd: string }) =>
        request({
            url: "/auth/sigin",
            method: "post",
            data: params,
        }),
    // 发送验证码接口
    reqCode: (mobile) =>
        request({
            url: `/auth/send/sms/code?mobile=${mobile}`,
            method: "post",
        }),
    // 判断账户有没有被绑定
    checkBind: (param: { mobile: string; code: string }) =>
        request({
            url: `/auth/sigup/verify/mobile`,
            method: "post",
            data: param,
        }),
    // 重置密码
    resetPwd: (param: { pwd: string; mobile: string; code: string }) =>
        request({
            url: `/auth/forgotpassword`,
            method: "post",
            data: param,
        }),
};
export default loginApi;
