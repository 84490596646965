import { Footer } from "antd/es/layout/layout";
import NationalEmblem from "../assets/svg/nationalemblem.svg";
function FooterBox() {
    // 注意key需要按顺序
    const data = [
        {
            key: 'info-1',
            label: '陕ICP备2023015784号-1',
            url: 'https://beian.miit.gov.cn/',
            svg: ''
        },
        {
            key: 'info-2',
            label: '增值电信业务经营许可证: 陕B2-20240134',
            url: 'https://shxca.miit.gov.cn/',
            svg: ''
        },
        {
            key: 'info-3',
            label: '陕公网安备61019002002816',
            url: 'https://beian.mps.gov.cn/#/query/webSearch?code=61019002002816',
            svg: NationalEmblem
        },
    ];
    return (
        <Footer className='footer-style'>
            <div className='width100 margin-auto'>
                {
                    data?.map((item) => {
                        return <span key={item?.key} className={`pointer ${item?.key != 'info-1' ? 'footerbox-filing-text' : ''}`} onClick={() => window.open(item?.url)}>
                            {
                                item?.svg ? <img src={item?.svg} alt='' className="footerbox-filing-img"/> : null
                            }
                            <span>{item?.label}</span>
                        </span>
                    })
                }
            </div>
        </Footer>
    )
}
export default FooterBox;