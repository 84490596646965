import { useEffect, useState } from 'react';
import withRouter from '../../hoc/withRouter';
import './AuthPanel.scss'
import DomainCard from '../DomainCard/DomainCard';
import { useImmer } from 'use-immer';
import { Utils } from '../../utils';
import AppCard from '../AppCard/AppCard';
import { Button, Input } from 'antd';
function AuthPanel({ title, cardData, type, bindDomain, change }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { setStore } = Utils();
    const [cardList, setCardList]: any = useImmer([]);
    const [cardListCopy, setCardListCopy]: any = useImmer([]);
    const [searchCardList, setSearchCardList]: any = useImmer([]);
    const [relayDomain, setRelayDomain]: any = useImmer({});
    // 服务大厅、工作台、知识库、CMDB、管理后台
    const plotOrder = ['serviceportal', 'workspace', 'faq', 'cmdb', 'domain'];
    const plotList = {
        serviceportal: {
            key: 'serviceportal',
            label: '服务大厅',
            url: '/f/case/sp',
            color: '#f3689a',
            img: require('../../assets/images/createapp.png')
        },
        workspace: {
            key: 'workspace',
            label: '工作台',
            url: '/f/case/ws',
            color: '#f3689a',
            img: require('../../assets/images/workbenchapp.png')
        },
        faq: {
            key: 'faq',
            label: '知识库',
            url: '/f/faq/',
            color: '#f3689a',
        },
        cmdb: {
            key: 'cmdb',
            label: '资产管理',
            url: '/f/faq/',
            color: '#f3689a',

        },
        domain: {
            key: 'domain',
            label: '管理后台',
            url: '/f/domain/',
            color: '#f3689a',
            img: require('../../assets/images/domainapp.png')
        }
    };
    // 起始下标
    const [index, setIndex] = useState(0);
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptText, setPromptText] = useState('');
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        setRelayDomain(bindDomain?.id ? bindDomain : {});
    }, [bindDomain])
    useEffect(() => {
        const list = JSON.parse(JSON.stringify(cardData));
        if (type == 'domain') {
            list.unshift({
                id: 'createDomain',
                name: '创建企业（机构）'
            });
            setCardList(list?.slice(0, 6) || []);
            setSearchCardList(list || []);
            setIndex(6);
        } else {
            setCardList(list || []);
        }
        setCardListCopy(list || []);
    }, [cardData])
    useEffect(() => {
        if (relayDomain?.id) {
            const list: any = cardListCopy?.filter((item: any) => item?.id != relayDomain?.id && item?.id != 'createDomain') || [];
            list?.unshift(relayDomain);
            list?.unshift({
                id: 'createDomain',
                name: '创建企业（机构）'
            });
            setCardList(list?.slice(0, 6));
            setIndex(6);
            setCardListCopy(list);
            setSearchCardList(list || []);
            localStorage.setItem("currentDomain", JSON.stringify(relayDomain));
            setStore('currentDomainInfo', relayDomain);
            const redirect_url = localStorage.getItem('redirect_url') || '';
            if (redirect_url && redirect_url != window.location.href) {
                localStorage.removeItem('redirect_url');
                window.open(redirect_url, '_self');
            }
        }

    }, [relayDomain]);

    // 然后将处理函数放到第三个位置**************************************************************
    const operate = (type, data?) => {
        switch (type) {
            case "cutOrg":
                // 切换域
                {
                    if (data?.id != 'createDomain') {
                        setRelayDomain(data || {});
                    } else {
                        // 创建管理域
                        change({ type: 'createDomain' })
                    }
                }
                break;
            case "selectApp":
                // 进入子应用
                {
                    // 创建管理域
                    change({ type: 'selectApp', data })
                }
                break;
            case "domain": {
                if (cardListCopy?.length == 1) {
                    return;
                }
                const time = setTimeout(() => {
                    const val = data?.target?.value || '';
                    let list = [];
                    if (val) {
                        list = cardListCopy?.slice(0, 1)?.concat([relayDomain], cardListCopy?.filter(item => {
                            return item?.id != 'createDomain' && (item?.id?.includes(val) || item?.name?.includes(val)) && item?.id != relayDomain?.id;
                        }));
                    } else {
                        list = JSON.parse(JSON.stringify(cardListCopy));
                    }
                    setIndex(6);
                    setSearchCardList(list);
                    setCardList(list?.slice(0, 6));
                    clearTimeout(time);
                }, 300);
            }
                break;
            case "pregroup": {
                if (searchCardList?.length >= 6 && index - 6 > 0) {
                    const lastIndex = index - (cardList?.length - 2) < 0 ? 6 : index - (cardList?.length - 2);
                    const baseList = searchCardList?.slice(0, 2);
                    const list = searchCardList?.slice(2, searchCardList?.length);
                    setCardList(baseList?.concat(list?.slice(lastIndex - 6, lastIndex - 2)));
                    setIndex(lastIndex < 0 ? 6 : lastIndex);
                } else {
                    setPromptText('已无上一组数据');
                    setShowPrompt(true);
                    const time = setTimeout(() => {
                        setShowPrompt(false);
                        clearTimeout(time);
                    }, 3000);
                }
            }
                break;
            case "nextgroup": {
                if (searchCardList?.length >= 6 && index < searchCardList?.length) {
                    const lastIndex = index + 6;
                    const baseList = searchCardList?.slice(0, 2);
                    const list = searchCardList?.slice(2, searchCardList?.length);
                    setCardList(baseList?.concat(list?.slice(index - 2 < 0 ? 0 : index - 2, lastIndex > searchCardList?.length ? lastIndex - 2 : lastIndex - 4)));
                    setIndex(lastIndex > searchCardList?.length ? searchCardList?.length : lastIndex - 2);
                } else {
                    setPromptText('已无下一组数据');
                    setShowPrompt(true);
                    const time = setTimeout(() => {
                        setShowPrompt(false);
                        clearTimeout(time);
                    }, 3000);
                }
            }
                break;
            default:
                break;
        }
    }
    return (
        <div className='auth-panel' data-component="auth-panel">
            <div className='flex-space-between align-items-center'>
                <div className="font-20 font-weight-800 margin-T20 margin-B20 shrink-0">{title}</div>
                {type == 'domain' ? <div className='search-domain'><Input placeholder="搜索企业名称/授权码" onChange={e => operate(type, e)} /></div> : null}
            </div>
            <div className='card-list'>
                {
                    type == 'domain' ?
                        <div>
                            <div className='flex-start flex-wrap'>
                                {cardList?.map((domain: any) => {
                                    return <div className='shrink-0 domain-card-style ' onClick={e => operate('cutOrg', domain)} key={domain?.id}>
                                        <DomainCard domain={domain} relayDomain={relayDomain} />
                                    </div>
                                })}
                            </div>
                            <div className='text-right'><Button onClick={e => operate('pregroup')}>上一组</Button><Button onClick={e => operate('nextgroup')}>下一组</Button></div>
                            {showPrompt ? <div className='text-right font-12 prompt-text margin-T10'>{promptText}</div> : null}
                        </div>
                        : null
                }
                {
                    type == 'app' && cardList?.length ?
                        <div className='flex-start flex-wrap'>
                            {
                                plotOrder?.map(ordkey => {
                                    if ((cardList || [])?.includes(ordkey)) {
                                        return (
                                            <div className='shrink-0 domain-card-style' key={ordkey} onClick={e => operate('selectApp', plotList[ordkey])}>
                                                <AppCard app={plotList[ordkey]} />
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div> : null
                }
            </div>
        </div>
    )
}
export default withRouter(AuthPanel);