import { Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import UserFile from '../pages/file/UserFile';
import PrivacyFile from '../pages/file/PrivacyFile';
import OfficialWeb from '../pages/OfficialWeb/OfficialWeb';
import HomeAuth from '../commons/HomeAuth/HomeAuth';
import Error from '../pages/Error/Error';
import Register from '../pages/Register/Register';
import Invite from '../pages/Invite/Invite';
import NotificationCenter from '../pages/NotificationCenter/NotificationCenter';
import AccountSetting from '../pages/AccountSetting/AccountSetting';

const routes = [
    {
        path: '/', //重定向到home
        element: <Navigate to='/home' />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/userfile',
        element: <UserFile />
    },
    {
        path: '/privacy',
        element: <PrivacyFile />
    },
    {
        path: "/invite",
        element: <Invite />
    },
    {
        path: '/notice',
        element: <OfficialWeb />,
        children: [
            {
                path: "/notice/overview",
                element: <NotificationCenter />
            },
            {
                path: "/notice/overview/:id",
                element: <NotificationCenter />
            },
            {
                path: '/notice',
                element: <Navigate to='/notice/overview' />
            }
        ],
    },
    {
        path: '/home',
        element: <OfficialWeb />,
        children: [
            {
                path: "/home/auth",
                element: <HomeAuth />

            },
            {
                path: "/home/usersetup",
                element: <AccountSetting />
            },
            {
                path: '/home',
                element: <Navigate to='/home/auth' />
            }
        ],
    },
    {
        path: '/error',
        element: <Error />
    },
    {
        path: '*',
        element: <Error />
    }
]
export default routes;