//服务api
import request from "../index";
const noticeapi = {
    //获取未读通知列表
    getUnreadNtc: (param: { key: string; value: string }) =>
        request({
            url: `/msgbox/msg/filter`,
            method: "post",
            data: param,
        }),
    // 修改当前登录人员在当前域中单个消息状态为已读:true
    setSingleNtcStatus: (param: { msgboxUuid: string; seen: boolean }) =>
        request({
            url: `/msgbox/msg/seen`,
            method: "post",
            data: param,
        }),
    // 修改当前登录人员在当前域中所有未读消息状态为已读:true
    setAllNtcStatus: () =>
        request({
            url: `/msgbox/msg/seen/all`,
            method: "post",
        }),
};

export default noticeapi;
