import withRouter from '../../hoc/withRouter';
import './file.scss'
function UserFile(props) {
    return (
        <div className='user-file'><section className="user-service-agreement">
            <h4>用户服务协议</h4>
            <h4>前言</h4>
            <main>
                <p>
                    欢迎访问并注册<span className="emphasis"
                    >综合服务管理平台</span
                    >并使用我们提供的产品和服务。
                </p>
                <p>
                    在完成注册程序或以任何方式使用本平台服务前，请您务必仔细阅读并透彻理解本平台服务协议（以下简称“服务协议”），在确定充分理解后，选择接受或不接受本服务完成“同意条款并注册”或开始以其他方式使用本平台服务，即表明您已阅读并同意受本服务协议的约束。如您不同意本服务协议或其中任何条款约定，您应
                    不再进行注册。
                </p>
                <article>
                    <br />
                    <h4>一、签约主体以及协议范围</h4>
                    <p>
                        本服务协议是您与西安点通软件信息技术有限公司（以下简称“点通”或者“我们”）就使用<span
                            className="emphasis"
                        >综合服务管理平台</span
                        >申请注册用户而订立的有效合约。
                    </p>
                    <br />
                    <h4>二、账户的注册、注销使用与安全</h4>
                    <p>2.1 &nbsp; 注册资格</p>
                    <p>
                        2.1.1 &nbsp;
                        您确认，在您完成注册程序或其他点通允许的方式实际使用本服务时，您应当时具备完全民事权利能力和完全民事行为能力的自然人、法人或者其他组织（以下统一简称为“您”）。
                    </p>
                    <p>
                        2.1.2 &nbsp;
                        若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果。
                    </p>
                    <p>
                        2.1.3 &nbsp;
                        您还需要确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则下限制的对象，否则您可能无法正常注册以后使用本服务。
                    </p>
                    <p>2.2 &nbsp; 账户注册</p>
                    <p>
                        2.2.1 &nbsp;
                        当您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册程序后，您可获得由西安点通信息技术有限公司提供的<span
                            className="emphasis"
                        >综合服务管理平台</span
                        >服务。
                    </p>
                    <p>
                        2.2.2 &nbsp;
                        您设置的账户名不得违反国家法律法规、公序良俗、社会公德。
                    </p>
                    <p>2.3 &nbsp; 账户的使用和安全</p>
                    <p>
                        2.3.1 &nbsp;
                        您有权使用您设定的账户登录综合服务管理平台，进而使用西安点通信息技术有限公司提供的<span
                            className="emphasis"
                        >综合服务管理平台</span
                        >服务。
                    </p>
                    <p>
                        2.3.2 &nbsp;
                        一个账户仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经点通同意的情况下，您不得以任何方式转让、赠与或让他人继承您的账号。
                    </p>
                    <p>
                        2.3.3 &nbsp;
                        本平台注册过程中输入您基本信息如姓名、电话号码等信息仅用于本平台使用。您的账号信息由您自行设置并由您保管。
                    </p>
                    <br />
                    <h4>三、平台服务及规范</h4>
                    <p>
                        3.1 &nbsp;
                        您有权享受本平台提供的综合服务管理平台的各项功能，包含：
                    </p>
                    <p>(1)&nbsp; 提交、处理服务请求</p>
                    <p>(2)&nbsp; 查询、统计历史记录</p>
                    <p>(3)&nbsp; 以及其他相关服务内容。</p>
                    <p>3.2 &nbsp; 您使用本平台时，您应保证：</p>
                    <p>
                        3.2.1 &nbsp;
                        您使用本平台时将遵从国家法律法规和社会公共道德，不会利用本平台提供的服务传播、发布如下信息和内容：
                    </p>
                    <div>
                        <p>
                            (1)&nbsp; 违反国家法律法规政策的任何内容（信息）；
                        </p>
                        <p>(2)&nbsp; 违反国家规定的政治宣传和/或新闻信息；</p>
                        <p>(3)&nbsp; 涉及国家秘密和/或安全的信息；</p>
                        <p>
                            (4)&nbsp;
                            封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；
                        </p>
                        <p>
                            (5)&nbsp;
                            博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；
                        </p>
                        <p>(6)&nbsp; 妨碍互联网运行安全的信息；</p>
                        <p>
                            (7)&nbsp;
                            侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；
                        </p>
                        <p>
                            (8)&nbsp;
                            煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一；
                        </p>
                        <p>
                            (9)&nbsp;
                            宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视；
                        </p>
                        <p>
                            (10)&nbsp;
                            从事非法侵入他人网络、干扰他人网络正常功能、窃取网络数据危害网络安全的活动；
                        </p>
                        <p>
                            (11)&nbsp;
                            您同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等；
                        </p>
                        <br />
                    </div>
                    <h4>四、隐私及个人信息的保护</h4>
                    <p>
                        点通依照《中国人民共和国个人信息保护法》、《中国人民共和国网络安全法》等相关法律法规要求，保护您的个人信息安全。
                    </p>
                    <p>
                        4.1 &nbsp; 本平台保存的个人信息，包含：<span
                            className="emphasis"
                        >姓名、手机号、归属组织；</span
                        >
                    </p>
                    <p>
                        4.2 &nbsp;
                        本平台保存的个人信息仅限于使用本平台提供的服务时，所必须的个人信息；
                    </p>
                    <p>
                        4.3 &nbsp;
                        本平台承诺会遵循合法、正当、必要和诚信的原则维护个人信息；
                    </p>
                    <p>
                        4.4 &nbsp;
                        同意本协议意味着，您同意本平台处理以上个人信息；
                    </p>
                    <p>
                        4.5 &nbsp;
                        未经您单独同意，本平台不得公开处理您的个人信息；
                    </p>
                    <p>4.6 &nbsp; 您可以通过下述的联系方式撤回以上同意；</p>
                    <p>
                        4.7 &nbsp;
                        点通收到您的撤回同意时，将删除平台保存的您的所有个人信息；
                    </p>
                    <p>
                        4.8 &nbsp;
                        如您发现本平台存储的个人信息有误，可通过下述联系方式予以更正；
                    </p>
                    <p>
                        4.9 &nbsp;
                        如本平台因合并、分立、解散、被宣告破产等原因需要转移个人信息的，会向您告知接收方名称或姓名和联系方式，接收方变更原先处理目的、处理方式的，需重新取得您同意。
                    </p>
                    <br />
                    <h4>五、保密</h4>
                    <p>
                        点通承诺对您注册时或使用过程中提交或知悉的信息采取保密措施，不向第三方披露您的信息。除非：
                    </p>
                    <p>
                        5.1 &nbsp;
                        依据法律法规的规定或行政、司法等职权部门要求应当提供的；
                    </p>
                    <p>
                        5.2 &nbsp;
                        在不违反本服务条款约定责任的前提下，该保密信息已经公开或能从公开领域获得；
                    </p>
                    <br />
                    <h4>六、知识产权声明</h4>
                    <p>
                        本平台包括文字、图片、内容、图形、logo等都收到版权法等一切知识产权公约保护。您同意遵守所有适用本网站的版权保护法律法规。
                    </p>
                    <br />
                    <h4>七、微信账号绑定登录</h4>
                    <p>
                        当您开启微信账号绑定登录时，表示您正在授权绑定微信账号，并且通过其在微信平台的有效登录状态，可直接登录您的本平台。且登录后所进行的所有本人通过该账号所进行的行为，您本人将对前述行为及后果承担相应的责任。
                    </p>
                    <p>
                        7.1 &nbsp;
                        微信账号无需输入账号和登录密码，即可登录本平台并允许一切操作，因此产生的风险和损失由您本人完全承担。
                    </p>
                    <p>
                        7.2 &nbsp;
                        本平台将读取该等微信账号的账户名、是否有效登录状态等，以确保微信账号快捷登录。
                    </p>
                    <br />
                    <h4>九、联系我们</h4>
                    <p>
                        如您对本协议有任何疑问，请通过以下方式联系我们：<br />电话：
                        029-89584658<br />电子邮件： info@dian-tong.com<br />地址：西安市高新区唐兴路6号唐兴数码大厦328室
                    </p>
                    <p>
                        西安点通软件信息技术有限公司<br />版本:
                        v1.0.1，自2022年11月25日起生效。
                    </p>
                </article>
            </main>
        </section></div>
    )
}

{/* export default UserFile; */}
export default withRouter(UserFile);
