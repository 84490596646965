import './NotificationCenter.scss';
import withRouter from '../../hoc/withRouter';
import { Spin, Typography, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { Utils } from '../../utils';
import noticeapi from '../../request/api/noticeapi';
import moment from 'moment';
function NotificationCenter({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { showMessage } = Utils();
    const [spinning, setSpinning] = useState(false);
    const pathId = router?.params?.id || ''; //获取路由携带的静态参数,目前是通知的uuid
    const [ntcList, setNtcList]: any = useState([]);
    const [curNtc, setCurNtc]: any = useState({});
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        // 获取全部通知列表
        getUnreadNtc();
    }, [])
    // 然后将处理函数放到第三个位置**************************************************************
    // 获取全部通知列表
    const getUnreadNtc = () => {
        setSpinning(true);
        const param = {
            key: '',
            value: '',
        };
        noticeapi.getUnreadNtc(param).then(res => {
            setSpinning(false);
            const data: any = res || [];
            const option = (data || [])?.map(item => {
                let link = item?.link?.split('&') || [], linkPathVal = '';
                let linkObj: any = {};
                for (const l of link) {
                    let arr = l?.split('=');
                    linkObj[arr[0]] = arr[1];
                }
                if (item?.source == 'Case') {
                    switch (linkObj?.angle) {
                        case 'customerContact': //点击跳转到服务大厅工单详情的link
                            linkPathVal = `/f/case/sp/detail/${linkObj?.uuid}`
                            break;
                        case 'assignedOwner': //点击跳转到工作台工单详情的link
                            linkPathVal = `/f/case/ws/detail/${linkObj?.uuid}`
                            break;
                        case 'serviceDesk': //点击跳转到服务单查询工单详情的link
                            linkPathVal = `/f/case/ws/sys/detail/${linkObj?.uuid}`
                            break;
                        default:
                            break;
                    }
                } else if (item?.source == "Wos") {
                    // 任务单通知
                    linkPathVal = `/f/case/ws/wkod/detail/${item?.itemID}`
                }
                return {
                    ...item,
                    linkPathVal
                }
            });
            if (pathId) {
                for (const item of option) {
                    if (item?.uuid == pathId) {
                        setCurNtc(item);
                        if (item?.seen == false) {
                            // 修改当前选中的通知为已读
                            setSingleNtcStatus(item, 'init', option);
                        }
                        break;
                    }
                }

            } else {
                setCurNtc(option[0] || {});
                if (option[0]?.seen == false) {
                    // 修改当前选中的通知为已读
                    setSingleNtcStatus(option[0], 'init', option);
                }
            }
            setNtcList(option);
        }).catch(err => {
            setSpinning(false);
            showMessage("error", err);
        })
    }
    // 修改单个通知状态为已读
    const setSingleNtcStatus = (data, type?, reqOption?) => {
        setSpinning(true);
        const param = { msgboxUuid: data?.uuid, seen: true };
        noticeapi.setSingleNtcStatus(param).then(res => {
            let list: any = [];
            if (!type) {
                list = JSON.parse(JSON.stringify(ntcList)) || [];
            } else if (type && reqOption) {
                list = JSON.parse(JSON.stringify(reqOption)) || [];
            }
            list?.forEach(item => {
                if (item?.uuid == data?.uuid) {
                    item.Seen = true;
                }
            });
            setNtcList(list);
            setSpinning(false);
        }).catch(err => {
            setSpinning(false);
            // 这里可以不用显示错误信息
            // showMessage("error", err);
        })
    }
    const operate = (type, data?) => {
        switch (type) {
            case 'selectNtc':
                setCurNtc(data);
                // 修改这个通知状态为已读
                if (data?.Seen == false) {
                    setSingleNtcStatus(data);
                }
                break;
            case 'gotolink':
                window.open(data?.linkPathVal);
                break;
            default:
                break;
        }
    }
    return (
        <div className='notification-center scrollbar width100 height100' data-component='notification-center'>
            <Spin tip="加载中..." spinning={spinning}>
                <div className='notification-center-bg scrollbar width100 height100 padding-B16'>
                    <div className='width-60 notification-center-box'>
                        <div className='notice-center-title'>通知中心</div>
                        <div className='notice-center-list'>
                            {
                                ntcList?.length ? ntcList?.map(item => {
                                    return <div key={item?.uuid} className={`ntc-item pointer padding-32 padding-T0 ${item?.uuid == curNtc?.uuid ? 'shadow-arrow' : ''}`} onClick={() => { operate('selectNtc', item); }}>
                                        <div className='border-top padding-T32'>
                                            <div className="ntc-top flex-space-between">
                                                <div className='flex-start'>
                                                    <span className={`margin-L4 flex-1 ${item?.seen ? 'select-color' : ''}`}>{item?.digest || '-'}</span>
                                                </div>
                                                <div className='ntc-time text-color shrink-0'>{moment(item?.createTime)?.format('YYYY-MM-DD HH:mm:ss')}</div>
                                            </div>
                                            <div className={`ntc-bottom margin-T8 select-color ${item?.uuid == curNtc?.uuid ? '' : 'none'}`}>
                                                <div className='flex-start'>
                                                    <div className='flex-start'>
                                                        <pre className='shrink-0 flex-1 pre-wrap'>{item?.content || '-'}</pre>
                                                    </div>
                                                </div>
                                                {
                                                    item?.link ? <div className='margin-T8 text-right'>
                                                        <Typography.Link
                                                            className='margin-L16'
                                                            onClick={() => operate('gotolink', item)}
                                                        >
                                                            查看详情
                                                        </Typography.Link>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }) : <Empty description="暂无未读通知数据" />
                            }
                        </div>
                    </div>
                </div>

            </Spin>
        </div>
    )
}

export default withRouter(NotificationCenter);