import handleApp from "./index";
let reducer = (state = {...handleApp.state}, action: { type: string; val: any }) => {
    // let newState = JSON.parse(JSON.stringify(state));
    let newState = Object.assign({},state);
    for (const key in handleApp.actionNames) {
        if(action.type == handleApp.actionNames[key]){
            handleApp.actions[handleApp.actionNames[key]](newState,action);
            break;
        }
    }
    return newState;
};
export default reducer;