import "./Invite.scss";
import withRouter from "../../hoc/withRouter";
import { Button, Form, Input, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import inviteapi from "../../request/api/inviteapi";
import { Utils } from "../../utils";
import LoginLogoSvg from '../../assets/svg/loginlogo.svg';
import UserAvatar from "../../commons/UserAvatar/UserAvatar";
import FooterBox from "../../commons/FooterBox";
import InviteBgSvg from "../../assets/svg/invitebg.svg";
import InviteDiamondSvg from "../../assets/svg/invitediamond.svg";
function Invite({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { showMessage, storeData } = Utils();
    const code = sessionStorage.getItem('inviteCode');
    const [userInfo, setUserInfo] = useState<any>({});
    const [joinOrg, setJoinOrg] = useState(false);
    const [domainInfo, setDomainInfo]: any = useState({});
    const [orgInfo, setOrgInfo]: any = useState({});
    const [spinning, setSpinning] = useState(false);
    const [domainName, setDomainName] = useState('');
    const [personID, setPersonID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [orgOption, setOrgOption] = useState([]);
    const [subDisabled, setDisabled] = useState(false);
    const [fullOrg, setFullOrg] = useState(false);
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        if (code) {
            setSpinning(true);
            verifyCode();
        }
    }, [code]);
    useEffect(() => {
        if (orgInfo?.organizePath && joinOrg) {
            setDisabled(false);
        } else if (!orgInfo?.organizePath && joinOrg) {
            setDisabled(true);
        }
    }, [orgInfo, joinOrg])
    useEffect(() => {
        if (fullOrg == true && domainInfo?.DomainUuid && personID) {
            // 获取域下所有组织
            getOwnOrgList(domainInfo?.DomainUuid);
        } else if (fullOrg == false && domainInfo?.DomainUuid && personID) {
            // 获取链接携带组织以及其子组织信息
            domainInfo?.OrganizationUuid && getOwnOrgList(domainInfo?.DomainUuid, domainInfo?.OrganizationUuid);
        }
    }, [fullOrg,domainInfo,personID])
    // 然后将处理函数放到第三个位置**************************************************************
    const verifyCode = () => {
        inviteapi.verifyCode(code).then(codeRes => {
            setSpinning(false);
            const data = codeRes['invitationCode'] || {};
            setDomainName(codeRes['domainName'] || '');
            setDomainInfo(data);
            setFullOrg(data?.FullOrg || false);
            // 判断这个人有没有在当前邀请链接的域中
            if (data?.DomainUuid) {
                setSpinning(true);
                const param = { domainID: data?.DomainUuid, organizationPath: `${data?.DomainUuid}/${data?.OrganizationUuid}`, admin: data?.Admin };
                inviteapi.memExists(param).then((res: any) => {
                    setSpinning(false);
                    const type = res['exist'] || '';
                    if (type == 'no member') { //用户不在部门里面，但在域里面
                        setPersonID(res?.member?.personID || res['personID']);
                        setPhoneNumber(res['phoneNumber'] || '');
                        setUserName(res['name'] || '');
                        setJoinOrg(true);
                    } else if (type == 'no person') { //用户不在域里面
                        setJoinOrg(false);
                    } else {
                        const memberData = res['member'] && res['member']?.metadata ? res['member']?.metadata : {};
                        setPersonID(res?.member?.personID || res['personID']);
                        setPhoneNumber(res['phoneNumber'] || memberData?.phone_number || '');
                        setUserName(memberData?.name || '')
                        setJoinOrg(true);
                    }
                }).catch(err => {
                    setSpinning(false);
                    if (err != 'no person') {
                        showMessage("error", err);
                    }
                })
            }
        }).catch(err => {
            setSpinning(false);
            // err = 'expired'表示邀请码过期
            // notexist 标识邀请码不存在
            showMessage("error", err);
            sessionStorage.removeItem("inviteCode");
            router.navigate(`/error?message=${err}`);
        })
    }
    // 获取域下所有组织 或者 对应organizePath组织及其子组织列表
    const getOwnOrgList = (domainid, orgid?) => {
        setSpinning(true);
        inviteapi.getOwnOrgList({ organizePath: orgid ? `${domainid}/${orgid}` : domainid }, domainid, personID).then((res: any) => {
            setOrgOption(res?.organize || [])
            if (res?.organize?.length == 1) {
                setOrgInfo(res?.organize[0]);
            }
            setSpinning(false);
        }).catch(err => {
            setSpinning(false);
        })
    }
    // 加入部门
    const confirmJoinOrg = (type, data?) => {
        let param;
        if (type == 'Confirm') {
            param = {
                userid: storeData?.uid,
                domainUuid: domainInfo['DomainUuid'] || '',
                organizePath: orgInfo?.organizePath || '',
                admin: domainInfo['Admin'],
                personUUid: personID,
            };
        } else {
            param = {
                userid: storeData?.uid,
                domainUuid: domainInfo['DomainUuid'] || '',
                organizePath: orgInfo?.organizePath || '',
                admin: domainInfo['Admin'],
                phoneNumber: data['mobile'],
                personUUid: personID,
                name: data['name']
            }
        }
        inviteapi.joinOrg(param).then(res => {
            if (type == 'Confirm') {
                showMessage("success", `成功加入部门${orgInfo?.pathName}！`);
                sessionStorage.removeItem('inviteCode');
                router.navigate('/home/auth');
            } else {
                showMessage("success", `成功加入企业（机构）${domainName}！`);
                if (res['personuuid']) {
                    setPersonID(res['personuuid']);
                }
                setUserInfo(data);
                setJoinOrg(true);
            }

        }).catch(err => {
            showMessage("error", err);
        })
    }
    const goAuth = () => {
        sessionStorage.removeItem("inviteCode");
        router.navigate('/home/auth');
    }
    const selectOrg = (val, option) => {
        setOrgInfo(option);
    }
    return (
        <div className="invite bg-white flex-space-between" data-component="invite">
            <div className='shrink-0 width-50 invite-bg'>
                <img src={LoginLogoSvg} alt="" className='margin-T30 margin-L30 pointer' height={24} width={150} onClick={() => window.open(`${window.location.origin}/f/website/`)} />
            </div>
            <div className="flex-column flex-1">
                <div className="flex-end invite-header">
                    <UserAvatar />
                </div>
                <div className="invite-box flex-1 flex-start align-items-center">
                    <div className="width100 invite-main relative">
                        <div className="top-bg">
                            <img src={InviteBgSvg} alt="" />
                            <div className="flex-start absolute invite-title margin-T20">
                                <img src={InviteDiamondSvg} alt="" width={20} height={20} />
                                <span className="font-20 color-white margin-L10">您受邀加入</span>
                            </div>
                        </div>
                        <div className="bottom relative word-break-all">
                            <Spin tip="加载中..." spinning={spinning}>
                                <div className="parcel">
                                    <div className="name margin-B30">{domainName}</div>
                                    <div className={[joinOrg ? 'none' : 'block']?.join(' ')}>
                                        <div className="font-14 margin-B10">请填写企业内信息</div>
                                        <Form
                                            name="invite-form"
                                            className="invite-form"
                                            onFinish={(e) => { confirmJoinOrg('next', e) }}
                                        >
                                            <Form.Item name="name" rules={[{ required: true, message: '值不能为空！', whitespace: true }]}>
                                                <Input placeholder="姓名" />
                                            </Form.Item>
                                            <Form.Item name="mobile">
                                                <Input placeholder="联系电话" />
                                            </Form.Item>
                                            <Form.Item >

                                                <Button type="primary" htmlType="submit" className="btn-style">
                                                    下一步
                                                </Button>
                                                <Button className="btn-style" type="primary" ghost onClick={goAuth}>
                                                    取消并返回主页
                                                </Button>

                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <div className={[joinOrg ? 'block' : 'none'].join(' ')} >
                                        <div className="font-14 margin-B10">企业内信息</div>
                                        <div className="margin-B20"><div className="info font-14 bold margin-B10">姓名</div><div className="value">{userInfo?.name || userName || '暂无'}</div></div>
                                        <div className="margin-B20"><div className="info font-14 bold margin-B10">联系电话</div><div className="value">{userInfo?.mobile || phoneNumber || '暂无'}</div></div>
                                        <div className="margin-B30"><div className="info font-14 bold margin-B10">部门</div>
                                            <Select
                                                className="select-style value width100"
                                                value={orgInfo?.organizePath}
                                                onChange={(val, option) => selectOrg(val, option)}
                                                options={orgOption}
                                                showSearch
                                                allowClear
                                                optionFilterProp='pathName'
                                                fieldNames={{ value: 'organizePath', label: 'pathName' }}
                                                placeholder="请选择组织"
                                            />
                                        </div>
                                        <div>
                                            <Button type="primary" onClick={() => confirmJoinOrg('Confirm')} className="btn-style" disabled={subDisabled}>
                                                确认加入
                                            </Button>
                                            <Button type="primary" ghost onClick={goAuth} className="btn-style">
                                                取消并返回主页
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>

                    </div>

                </div>
                <FooterBox />
            </div>
        </div>
    );
}

export default withRouter(Invite);
