import './OfficialWeb.scss';
import withRouter from '../../hoc/withRouter';
import { Outlet } from 'react-router-dom';
import { Layout, Space } from "antd";
import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../utils';
import FooterBox from '../../commons/FooterBox';
import logoSvg from "../../assets/svg/logo.svg";
import UserAvatar from '../../commons/UserAvatar/UserAvatar';
const { Header, Content } = Layout;
function OfficialWeb({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { setStore, storeData } = Utils();
    //获取official-website
    const webConRef = useRef(null);
    const [orgList, setOrgList] = useState<any[]>([]);
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        if (storeData?.homeAuthCut) {
            const list = orgList?.map(ad => {
                const active = ad['id'] == storeData?.domainId ? true : false;
                return {
                    ...ad,
                    active
                }
            });
            setOrgList([...list]);
        }
    }, [storeData?.homeAuthCut])
    useEffect(() => {

    }, []);
    // 然后将处理函数放到第三个位置**************************************************************
    return (
        <div className='official-web' data-component='official-web'>
            <div className='official-web height100'>
                <Layout className='height100'>
                    <Header>
                        <div className='header-div'>
                            <div className="logo pointer" >
                                <img src={logoSvg} alt='' onClick={() => window.open(`${window.location.origin}/f/website/`)} />
                            </div>
                            <UserAvatar />
                        </div>
                    </Header>
                    <Content className="home-layout scrollbar" ref={webConRef}>
                        <Outlet></Outlet>
                    </Content>
                    <FooterBox />
                </Layout >
            </div >
        </div >
    )
}

export default withRouter(OfficialWeb);

