// 抽取reducer
const store = {
    state: {
        name: "",
        uid: "", //用户id
        orgList: [],
        currentDomainInfo: "",
        homeAuthCut: false, //当前点击的是哪个界面的切换管理域
        displayName:''
    },
    // 存放同步函数
    actions: {
        name(newState, action) {
            newState.name = action.val;
        },
        uid(newState, action) {
            newState.uid = action.val;
        },
        orgList(newState, action) {
            newState.orgList = action.val;
        },
        currentDomainInfo(newState, action) {
            newState.currentDomainInfo = action.val;
        },
        homeAuthCut(newState, action) {
            newState.homeAuthCut = action.val;
        },
        displayName(newState, action) {
            newState.displayName = action.val;
        },
    },
    actionNames: {},
};
// actionNames自动生成
let actionNames = {};
for (const key in store.actions) {
    actionNames[key] = key;
}
store.actionNames = actionNames;
export default store;
