import "./UserAvatar.scss";
import withRouter from "../../hoc/withRouter";
import { Dropdown, Modal } from "antd";
import { Utils } from "../../utils";
import { DownOutlined } from "@ant-design/icons";
import cookie from "react-cookies";
import usersetupapi from "../../request/api/usersetupapi";
import { useEffect, useState } from "react";
function UserAvatar({ router }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const { setStore, storeData } = Utils();
    // 头部用户个人信息显示参数
    const logoutConfig = {
        content: '您确定要退出当前系统吗？',
        icon: null,
        okText: "确定",
        cancelText: '取消',
        centered: true,
        maskClosable: true,
        onCancel: () => {
        },
        onOk: () => {
            logout();
        }
    };
    const [modal, contextHolder] = Modal.useModal();
    const [displayName, setDisplayName] = useState(storeData?.displayName || '');
    const [name, setName] = useState(storeData?.name || '');
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        // 获取用户信息
        getUserInfo();
    }, [])
    // 然后将处理函数放到第三个位置**************************************************************
    // 退出登录
    const logout = () => {
        ['name', 'displayName', 'orgList', 'homeAuthCut', 'uid']?.forEach(key => {
            setStore(key, '');
        })
        cookie.remove('gsp-jwt');
        localStorage.removeItem('currentDomain');
        localStorage.removeItem('token');
        localStorage.removeItem('wsCurOrg');
        localStorage.removeItem('spCurOrg');
        localStorage.removeItem('lastupdatetime');
        localStorage.removeItem('UserAcceptPush');
        localStorage.removeItem('ConnectionTab');
        router.navigate('/home');
    }
    const operate = (type, data?) => {
        switch (type) {
            case 'accountSetting':
                window.open(window.location.origin + `/f/website/home/usersetup`)
                break;
            default:
                break;
        }
    }
    // 获取用户信息
    const getUserInfo = () => {
        usersetupapi.getUserInfo().then((res: any) => {
            const data = res?.user || {};
            setStore('displayName', data?.displayName);
            setStore('name', data?.name);
            setDisplayName(data?.displayName || '');
            setName(data?.name || '');
        }).catch(err => {
        })
    }
    return (
        <div className="user-avatar" data-component="user-avatar">
            <div className='name'>
                <Dropdown overlayClassName="website-case-user" dropdownRender={() => (
                    <div>
                        <div className='name-info'>
                            <div className='fixed-data flex-column'>
                                <div className="pointer" onClick={e => operate('accountSetting')}>账户设置</div>
                            </div>
                            <div className='logout'>
                                <div onClick={(e) => {
                                    modal.confirm(logoutConfig)
                                }}>
                                    <span>退出登录</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )} placement="bottom" trigger={['click']}>
                    <div className='user-name flex pointer'>
                        <div className='user-name-flag'>{displayName?.charAt(0) || name?.charAt(0)}</div>
                        <div className='name bold font-14'>{displayName || name}</div>
                        <div className='user-name-icon' >
                            <DownOutlined style={{ fontSize: '10px', color: '#4E5969' }} />
                        </div>
                    </div>
                </Dropdown>
                {contextHolder}
            </div>
        </div>
    );
}

export default withRouter(UserAvatar);
