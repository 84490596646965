import {
    legacy_createStore,
    combineReducers,
    compose,
    applyMiddleware,
} from "redux";
import reduxThunk from "redux-thunk";
import handleApp from "./AppStatus/redecer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "website",
    storage: storage,
    whitelist: ["handleApp"],
    // blacklist: [], //设置某个reducer数据不持久化
};
const handleAppConfig = {
    key: "websiteApp",
    storage: storage,
};
// combineReducers：组合各个模块的reducer
const reducer = combineReducers({
    handleApp: persistReducer(handleAppConfig, handleApp),
});
const persistedReducer = persistReducer(persistConfig, reducer);
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__({})
    : compose;
const store = legacy_createStore(
    persistedReducer,
    // 如果不适用thunk呢
    composeEnhancers()
    //composeEnhancers(applyMiddleware(reduxThunk))
);
export const persistor = persistStore(store);
export default store;