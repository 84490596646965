
import React, { useEffect } from "react";
import './App.scss';
import router from './routes';
import { useRoutes } from "react-router-dom";
import withRouter from "./hoc/withRouter";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { Utils } from "./utils";
function ToPage({ router, toUrl }) {
  useEffect(() => {
    setTimeout(() => {
      router.navigate(toUrl);
    }, 0);
  }, []);
  return <></>
}
// 路由守卫
function BeforeRouterEnter(props) {
  const { setStore, storeData } = Utils();
  const token = localStorage.getItem("token");
  const outlet = useRoutes(router);
  let currentUrl = props?.router?.location.pathname;
  // 通过判断登录状态来改变浏览器标题
  if (token && (storeData?.displayName || storeData?.name)) {
    document.title = (storeData?.displayName || storeData?.name) + '-ServiceCool';
  } else {
    document.title = 'ServiceCool';
  }
  // 通过邀请链接进入系统情况（/f/website/invite/member/邀请码）
  if (currentUrl?.includes('/invite/member') && currentUrl?.split('/')?.length == 4) {
    const code = currentUrl?.split('/')[3];
    sessionStorage.setItem('inviteCode', code);
    // 情况1：用户已经登录了系统
    if (token) {
      return <ToPage {...props} toUrl={'/invite'} />
    } else {
      // 情况2：用户没有登录系统
      return <ToPage {...props} toUrl={'/login'} />
    }
  }
  if (!['/userfile', '/privacy', '/register']?.includes(currentUrl) && ['/login']?.includes(currentUrl) && token) {
    return <ToPage {...props} toUrl={'/home/auth'} />
  }
  if (!['/userfile', '/privacy', '/register', '/', '/home', '/login']?.includes(currentUrl) && !token) {
    localStorage.setItem('redirect_url', window.location.href);
    return <ToPage {...props} toUrl={'/login'} />
  }
  return outlet
}

function App(props) {
  return (
    <div className="app">
      {/* 编程式导航跳转需要用到hook */}
      <ConfigProvider
        theme={{
          token: {
            // 组件主题色
            colorPrimary: '#2367FD',
            colorBorder: '#DDE1E9'
          },
        }}
      ><ConfigProvider locale={zhCN}>
          <BeforeRouterEnter {...props} />
        </ConfigProvider>
      </ConfigProvider>

    </div>
  );
}
export default withRouter(App);
