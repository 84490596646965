import { Button, Result } from 'antd';
import React from 'react'
import withRouter from '../../hoc/withRouter';
import FooterBox from '../../commons/FooterBox';

function Error({ router }) {
  const message = router?.query?.message || '';
  return (
    <div className='flex-column flex-space-between height100'>
      <Result
        status="404"
        title="404"
        subTitle={message || "抱歉，您访问的界面不存在"}
        extra={
          <Button type="primary" onClick={() => router.navigate('/')}>
            返回主页
          </Button>
        }
      />
      <FooterBox />
    </div>
  )
}

export default withRouter(Error);