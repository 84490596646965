import { PlusCircleOutlined } from '@ant-design/icons';
import withRouter from '../../hoc/withRouter';
import DomainSvg from '../../assets/svg/domain.svg';
import DomainSelectSvg from '../../assets/svg/domainselect.svg';
import './DomainCard.scss'
import { useEffect, useState } from 'react';
function DomainCard({ domain, relayDomain }) {
    // 将所有需要的变量都放到最顶上********************************************************************
    const [bindDomian, setBindDomain] = useState(relayDomain);
    // 然后将钩子函数放到第二个位置**************************************************************
    useEffect(() => {
        setBindDomain(relayDomain || {});
    }, [relayDomain])
    // 然后将处理函数放到第三个位置**************************************************************
    return (
        <div className='domain-card pointer radius-10 margin-B12' data-component="domain-card">
            {
                domain?.id == 'createDomain' ?
                    <div className='create-content radius-10 '>
                        <div className='text-center margin-auto'>
                            <PlusCircleOutlined />
                            <div className='margin-T10'>{domain?.name}</div>
                        </div>
                    </div>
                    :
                    <div className={`domain-content flex-column radius-10 relative ${bindDomian?.id != domain?.id ? 'domain-hover-style' : ''} ${bindDomian?.id == domain?.id ? 'select-style' : ''}`}>
                        {
                            bindDomian?.id == domain?.id ? <div className='absolute select-tag font-12'>当前</div> : null
                        }
                        <div className='top flex-1 word-break-all padding-20'>
                            <div className='icon margin-T10'>
                                <img src={bindDomian?.id == domain?.id ? DomainSelectSvg : DomainSvg} alt="" />
                            </div>
                            <div className='bold font-14 text-hidden row-2-hidden'>{domain?.name}</div>
                        </div>
                        <div className='bottom font-12 bold shrink-0'>
                            <div>切换</div>
                        </div>
                    </div>
            }
        </div>
    )
}
export default withRouter(DomainCard);