import request from "../index";

const officialwebapi = {
    // 获取当前登录人加入的管理域
    getJoinOrgList: () =>
        request({
            url: `/dash/self/domains`,
            method: "get",
        }),
    // 获取当前登录人创建的管理域
    getCreateOrgList: () =>
        request({
            url: `/dash/self/domains/admin`,
            method: "get",
        }),
};
export default officialwebapi;