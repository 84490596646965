import request from "../index";

const usersetupapi = {
    // 获取用户信息
    getUserInfo: () =>
        request({
            url: `/dash/user`,
            method: "get",
        }),
    // 修改昵称
    subNickName: (param: { displayName: string }) =>
        request({
            url: `/dash/user/displayname`,
            method: "put",
            data: param,
        }),
    // 修改用户名
    subName: (param: { name: string }) =>
        request({
            url: `/dash/user/name`,
            method: "put",
            data: param,
        }),
    // 修改邮箱地址
    subEmail: (param: { email: string }) =>
        request({
            url: `/dash/user/email`,
            method: "put",
            data: param,
        }),
};
export default usersetupapi;
